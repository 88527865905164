import React, { useState, useEffect } from 'react';
import HeaderApp from '../../header/HeaderApp';
import MenuApp from '../../constants/MenuApp';
import { Breadcrumb, Form, Input, Button, Space, Table, Tag, Modal, Switch, Pagination } from 'antd'
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import { RiEditCircleFill, RiAddCircleFill } from "react-icons/ri";
import { Grid } from '@mui/material';
import responseHandleSrv from '../../services/responseHandleSrv';
import axios from '../../axios';
import { authHeader } from '../../services/auth-header';
import Api from '../../constants/Api';
import { tagLabelActive } from '../../services/function';

export default function SaleType() {
  const { confirm } = Modal;
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOverlay, setOverlay] = useState(false);
  const [pageData, setPageData] = useState({
    currentPage: 1,
    pageSize: 10,
    totalPage: '',
    previousPage: '',
    nextPage: '',
    totalcount: '',
    goToPage: ''
  });
  const [filter, setFilter] = useState({
    search: '',
    IsActive: ''
  });
  const [valueID, setValueID] = React.useState('');
  const [form] = Form.useForm();
  const [formadd] = Form.useForm();
  const [formedit] = Form.useForm();

  useEffect(() => {
    onClear();
  }, [])

  const getData = (page, pageSize, search) => {
    (async () => {
      setIsLoading(true);
      await axios.get(`${Api.SALETYPE_SEARCH_LIST}`, {
        headers: authHeader(),
        params: {
          search,
          page,
          skip: pageSize,
        }
      }).then(response => {
        if (response.data.success) {
          setData(response.data.data.result);
          let dd = {
            ...pageData,
            "currentPage": page,
            "pageSize": pageSize,
            "totalPage": response.data.totalPage,
            "previousPage": response.data.previousPage,
            "nextPage": response.data.nextPage,
            "totalcount": response.data.data.totalCount,
            "goToPage": ''
          }
          setPageData(dd);
        }
        setIsLoading(false)
      }).catch(err => {
        setIsLoading(false)
        responseHandleSrv.handleError(err);
      });
    })();
  }

  const onClear = () => {
    form.resetFields();
    getData(1, pageData.pageSize, '');
    let dd = {
      ...pageData,
      "currentPage": 1,
      "pageSize": pageData.pageSize,
      "totalPage": '',
      "previousPage": '',
      "nextPage": '',
      "totalcount": ''
    }
    setPageData(dd);

    let ft = {
      ...filter,
      "search": '',
    }
    setFilter(ft);
  }

  const onChangePage = (page, pageSize) => {

    let pp = {
      ...pageData,
      "currentPage": page,
      "pageSize": pageSize
    }
    setPageData(pp);

    getData(page, pageSize, filter.search);

  }
  const showModdalCreate = () => {
    setVisibleCreate(true);
  }
  const showModalEdit = (record) => {
    setVisibleEdit(true);
    setValueID(record.Id);
    formedit.resetFields();
    formedit.setFieldsValue({
      'Name': record.NameTh ? record.NameTh : '',
      'RichMenu': record.RichMenu ? record.RichMenu : '',
      'RichMenuId': record.RichMenuId ? record.RichMenuId : '',
      'Description': record.Description ? record.Description : '',
      'IsActive': record.IsActive === 1 ? true : false,
    });

  }
  const showModalDelete = (record) => {
    confirm({
      title: 'คุณแน่ใจที่จะลบรายการนี้?',
      okText: 'ใช่',
      okType: 'danger',
      okButtonProps: { className: 'dangerOutlAnimate' },
      cancelButtonProps: { className: 'radiusBtn' },
      cancelText: 'ไม่',
      centered: true,
      closable: true,
      onOk() {
        (async () => {
          setIsLoading(true);
          await axios.delete(`${Api.SALETYPE_UPDATE + record.Id}`, {
            headers: authHeader()
          }).then(response => {
            if (response.data.success) {
              responseHandleSrv.handleSuccess(response);
              onClear();
            }
          }).catch(err => {
            setIsLoading(false)
            responseHandleSrv.handleError(err);
          });
        })();
      },
      onCancel() { },
    });
  }

  const handleOk = () => {
    setVisibleCreate(false);
  }
  const handleCancel = () => {
    setVisibleCreate(false);
    formadd.resetFields();
    onClear();
  }
  const handleCancelEdit = () => {
    setVisibleEdit(false);
    setValueID('');
    formedit.resetFields();
    onClear();
  }

  const onFinish = (values) => {
    let search = values.search !== undefined ? values.search : '';
    let ft = { ...filter, "search": search }
    setFilter(ft);
    getData(1, pageData.pageSize, search);
  }

  const onFinishAdd = (values) => {
    let params = {
      Name: values.Name !== undefined ? values.Name : '',
      RichMenu: values.RichMenu !== undefined ? values.RichMenu : '',
      RichMenuId: values.RichMenuId !== undefined ? values.RichMenuId : '',
      Description: values.Description !== undefined ? values.Description : '',
      IsActive: values.IsActive !== undefined ? values.IsActive : false
    };
    (async () => {
      setIsLoading(true);
      setOverlay(true);
      await axios.post(`${Api.SALETYPE_CREATE}`, params, {
        headers: authHeader()
      }).then(response => {
        if (response.data.success) {
          responseHandleSrv.handleSuccess(response);
          handleCancel();
          setIsLoading(false);
          setOverlay(false);
        }
      }).catch(err => {
        setIsLoading(false)
        setOverlay(false);
        responseHandleSrv.handleError(err);
      });
    })();
  }

  const onFinishEdit = (values) => {

    let params = {
      Name: values.Name !== undefined ? values.Name : '',
      RichMenu: values.RichMenu !== undefined ? values.RichMenu : '',
      RichMenuId: values.RichMenuId !== undefined ? values.RichMenuId : '',
      Description: values.Description !== undefined ? values.Description : '',
      IsActive: values.IsActive !== undefined ? values.IsActive : false
    };
    
    (async () => {
      setIsLoading(true);
      setOverlay(true);
      await axios.put(`${Api.SALETYPE_UPDATE + valueID}`, params, {
        headers: authHeader()
      }).then(response => {
        if (response.data.success) {
          responseHandleSrv.handleSuccess(response);
          handleCancelEdit();
          setIsLoading(false);
          setOverlay(false);
        }
      }).catch(err => {
        setIsLoading(false)
        setOverlay(false);
        responseHandleSrv.handleError(err);
      });
    })();
  }

  const columns = [
    {
      title: 'ชื่อ',
      key: 'NameTh',
      dataIndex: 'NameTh',
      width: 250,
      align: 'center'
    },
    {
      title: 'RichMenu',
      key: 'RichMenu',
      dataIndex: 'RichMenu',
      align: 'center',
      render: (text, record) => (
        <div>{text ? text : '-'}</div>
      )
    },
    {
      title: 'RichMenuId',
      key: 'RichMenuId',
      dataIndex: 'RichMenuId',
      align: 'center',
      width: '200',
      render: (text, record) => (
        <div>{text ? text : '-'}</div>
      )
    },
    {
      title: 'คำอธิบาย',
      key: 'Description',
      dataIndex: 'Description',
      align: 'center',
      render: (text, record) => (
        <div>{text ? text : '-'}</div>
      )
    },
    {
      title: 'สถานะการใช้งาน',
      key: 'IsActive',
      dataIndex: 'IsActive',
      align: 'center',
      render: (text, record) => (
        <>
          {
            text === 0 ? <div><Tag color={tagLabelActive[record.IsActive]}>ปิดการใช้งาน</Tag></div>
              : <div><Tag color={tagLabelActive[record.IsActive]}>เปิดการใช้งาน</Tag></div>
          }

        </>
      )
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 130,
      render: (text, record) => (
        <Space size="middle">
          <Button type="text" shape="circle" icon={<AiOutlineEdit />} onClick={() => showModalEdit(record)} />
          <Button type="text" shape="circle" danger icon={<AiOutlineDelete />} onClick={() => showModalDelete(record)} />
        </Space>
      ),
    },
  ];

  return (
    <>
    <div id="overlay" style={{display: isOverlay === true ? "block" : "none"}}><div class="text-overlay-center">Loading...</div></div>
      <div className="page-container">
        <HeaderApp />
        <div className='pageSider'>
          <MenuApp />
          <div className="main-content">

            {/* modal create */}
            <Modal
              title={<div className='modalHeader'><RiAddCircleFill size='20px' />&nbsp;&nbsp;Add Sale Type</div>}
              open={visibleCreate}
              footer={false}
              onOk={handleOk}
              onCancel={handleCancel}
              centered='true'
              className='modalCreate'

            >
              <Form
                form={formadd}
                layout='vertical'
                name='create-sale-type'
                onFinish={onFinishAdd}
                onFinishFailed={responseHandleSrv.onFinishFailed}
                initialValues={{ 'IsActive': true }}
              >
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='ชื่อ'
                      name='Name'
                      rules={[{ required: true, message: 'กรอกชื่อ' }]}
                    >
                      <Input placeholder='กรอกชื่อ' />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='Rich Menu'
                      name='RichMenu'
                    >
                      <Input placeholder='กรอก Rich Menu' />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='Rich Menu Id'
                      name='RichMenuId'

                    >
                      <Input placeholder='กรอก Rich Menu Id' />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='คำอธิบาย'
                      name='Description'
                    >
                      <Input placeholder='กรอกคำอธิบาย' />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='สถานะการใช้งาน'
                      name='IsActive'
                      valuePropName='checked'>
                      <Switch defaultChecked checkedChildren='เปิด' unCheckedChildren='ปิด' />
                    </Form.Item>
                  </Grid>
                </Grid>
                <div className='df-flexEnd'>
                  <Button onClick={() => handleCancel()} className='radiusBtn'>Cancel</Button>&nbsp;
                  <Button type='primary' htmlType='submit' className='primaryBtnAnimate'>Save</Button>
                </div>
              </Form>
            </Modal>

            {/* modal edit */}
            <Modal
              title={<div className='modalHeader'><RiEditCircleFill size='20px' />&nbsp;&nbsp;Edit Sale Type</div>}
              open={visibleEdit}
              footer={false}
              onOk={handleOk}
              onCancel={handleCancelEdit}
              centered='true'
              className='modalCreate'
            >
              <Form
                form={formedit}
                layout='vertical'
                name='edit-sale-type'
                onFinish={onFinishEdit}
                onFinishFailed={responseHandleSrv.onFinishFailed}
              >
                <Grid container columnSpacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='ชื่อ'
                      name='Name'
                      rules={[{ required: true, message: 'กรอกชื่อ' }]}
                    >
                      <Input placeholder='กรอกชื่อ' />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='Rich Menu'
                      name='RichMenu'
                    >
                      <Input placeholder='กรอก Rich Menu' />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='Rich Menu Id'
                      name='RichMenuId'
                    >
                      <Input placeholder='กรอก Rich Menu Id' />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Form.Item
                      label='คำอธิบาย'
                      name='Description'
                    >
                      <Input placeholder='กรอกคำอธิบาย' />
                    </Form.Item>
                  </Grid>
                  <Grid item xs={12}>
                    <Form.Item label='สถานะการใช้งาน' valuePropName='checked' name='IsActive'>
                      <Switch checkedChildren='เปิด' unCheckedChildren='ปิด'></Switch>
                    </Form.Item>
                  </Grid>
                </Grid>
                <div className='df-flexEnd'>
                  <Button onClick={() => handleCancelEdit()} className='radiusBtn'>Cancel</Button>&nbsp;
                  <Button type='primary' htmlType='submit' className='primaryBtnAnimate'>Save</Button>
                </div>
              </Form>
            </Modal>


            <div className='pdPage'>
              <div className='buttonHeader ' >
                <div>
                  <b className='pageTitle'>Sale Type</b>
                  <Breadcrumb className='breadcrumbStyle' items={[{ title: 'Sale Type', href: '/backend/saleType' }]} />
                </div>
                <Button type='primary' onClick={() => showModdalCreate()} className='df-centerY primaryBtnAnimate'><AiOutlinePlus />&nbsp;&nbsp;Add Sale Type</Button>
              </div>

              <div className='searchCard'>
                <Form
                  form={form}
                  layout='vertical'
                  name='search-table'
                  onFinish={onFinish}
                >
                  <Grid container columnSpacing={2}>
                    <Grid item xs={12} sm={8} md={6} lg={4}>
                      <Form.Item label='ค้นหา' name='search'>
                        <Input placeholder='ชื่อ' />
                      </Form.Item>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={6} className='df-centerY'>Result: {pageData.totalcount} {pageData.totalcount > 0 ? (pageData.totalcount === 1 ? "item" : "items") : ""}</Grid>
                    <Grid item xs={12} sm={6} className='searchBtnOrg'>
                      <Button type='primary' htmlType='submit' className='df-centerY primaryBtnAnimate'><AiOutlineSearch />&nbsp;&nbsp;ค้นหา</Button>&nbsp;
                      <Button danger className='df-centerY dangerOutlAnimate' onClick={() => onClear()}><AiOutlineDelete />&nbsp;&nbsp;เคลียร์</Button>
                    </Grid>
                  </Grid>
                </Form>
              </div>
              <div>
                {isLoading === true ?
                  <div className="linearloader" /> :
                  <>
                    <Table
                      className='headerTable-color listData-card'
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      scroll={{ x: 1900 }}
                      rowKey={record => record.Id}
                      rowClassName={'tableBody'}
                    />
                    <Pagination
                      total={pageData.totalcount}
                      showSizeChanger
                      showQuickJumper
                      current={pageData.currentPage}
                      defaultPageSize={pageData.pageSize}
                      onChange={onChangePage}
                      responsive={true}
                      className="tableBottom"
                    />
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
