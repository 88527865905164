import React, { useState } from 'react'
import { Button, Form, Input } from 'antd';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import responseHandleSrv from '../.././../services/responseHandleSrv';
import otpSrv from '../../../services/otpSrv';

export default function VerifyMobile(props) {
    const { onNextPage, onPrevPage, profileValue, lineProfile, setMobileNoProp } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [checkBtn, setCheckBtn] = useState(true);
    const onFinish = (values) => {
        setMobileNoProp(values.mobileNo);
        sendOtp(values.mobileNo)
    };

    const sendOtp = (mobileNo) => {
        if (mobileNo !== undefined) {
            (async () => {
                // setIsLoading(true)
                await otpSrv.sendOtp(mobileNo).then(response => {
                    if (response.data.success) {
                        let res = response.data.data;
                        onNextPage();
                    }
                }).catch(err => {
                    setIsLoading(false)
                    responseHandleSrv.handleError(err);
                });
            })();
        }
    }
    const onChangeMobileNo = (e) => {

        if (e.target.value) {
            if (e.target.value.length === 10) {
                setCheckBtn(false);
            } else {
                setCheckBtn(true);
            }
        } else {
            setCheckBtn(true);
        }

    };
    return (
        <div style={{ padding: '5%' }}>
            <div className='df-center'>ข้อมูลส่วนตัว</div><br/>
            <div>รหัส:&nbsp;<b className='primaryColor'>{profileValue.Code}</b></div>
            <div>ชื่อ:&nbsp;<b className='primaryColor'>{profileValue.FullName}</b></div>
            <div>ชื่อบริษัท:&nbsp;<b className='primaryColor'>{profileValue.DistributorName ? profileValue.DistributorName : "-"}</b></div>
            <div>กลุ่มการขาย:&nbsp;<b className='primaryColor'>{profileValue.ShortName ? profileValue.ShortName : '-'}</b></div>
            <div>เบอร์โทรศัพท์มือถือ สำหรับ OTP<span style={{ color: 'red' }}>*</span></div>
            <br />
            <Form
                onFinish={onFinish}
                onFinishFailed={responseHandleSrv.onFinishFailed}
            >
                <Form.Item
                    name="mobileNo"
                    rules={[
                        {
                            required: true,
                            message: 'กรอกเบอร์โทรศัพท์มือถือ'
                        },
                        {

                            pattern: /^[0][0-9]{9}$\b/,
                            message: 'กรุณากรอกเบอร์โทรศัพท์มือถือให้ถูกต้อง',
                        }
                    ]}
                >

                    <Input placeholder='กรอกเบอร์โทรศัพท์มือถือ' maxLength={10} onChange={onChangeMobileNo} />

                </Form.Item>
                <div className='df-center'>
                    <Button type='primary' htmlType='submit' style={{ width: '100%' }} disabled={checkBtn} className='radiusBtn'>ขอรหัส OTP</Button>
                </div>
            </Form>

            {/* <br />
            <div className='df-spaceBtw'> */}
                {/* <Button className='df-centerY' shape='round' onClick={() => onPrevPage()}><AiOutlineArrowLeft />&nbsp;&nbsp;Back</Button> */}
                {/* <Button className='df-centerY' shape='round' onClick={() => onNextPage()}>Next&nbsp;&nbsp;<AiOutlineArrowRight /></Button> */}
            {/* </div> */}
        </div>
    )
}
