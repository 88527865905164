import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { AiOutlineSearch } from "react-icons/ai";
import responseHandleSrv from '../../../services/responseHandleSrv';
import axios from '../../../axios';
import Api from '../../../constants/Api';
import { authBasicHeader } from '../../../services/auth-header';

export default function SearchSale(props) {
    const [data, setData] = useState({});
    const [checkData, setCheckData] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    // const { error, isLoggedIn, isReady, liff } = useLiff();

    const { onNextPage, setProfileProp, lineProfile} = props;
    const root = window.location.origin;


    const onFinish = (values, count) => {
        let code = values.SaleCode !== undefined ? values.SaleCode : '';
        (async () => {
            setIsLoading(true)
            await axios.get(`${Api.SALE_SEARCH_CODE + code}`, {
                headers: authBasicHeader()
            }).then(response => {
                if (response.data.success) {
                    let data = response.data.data;
                    setData(data);
                    setIsLoading(false);
                    setProfileProp(data);
                    onNextPage(count);
                }
            }).catch(err => {
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    };
    return (
        <>
            {/* {
                isLoading === true ? 'กำลังตรวจสอบข้อมูล...' : ''
            }
            {checkData === true && */}
            <div className='dfc-centerXYpage' style={{ padding: '5%' }}>
                <div className='df-center'>กรุณากรอกรหัสพนักงานขาย</div>
                <Form
                    layout='vertical'
                    onFinish={onFinish}
                    onFinishFailed={responseHandleSrv.onFinishFailed}
                >
                    <Form.Item
                        name="SaleCode"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอกรหัสพนักงานขาย'
                            }
                        ]}
                    >
                        <Input
                            prefix={<AiOutlineSearch />}
                            placeholder="กรอกรหัสพนักงานขาย"
                            maxLength={10} />
                    </Form.Item>
                    <div className='df-center'>
                        <Button type='primary' htmlType="submit" style={{ width: '50%' }} className='radiusBtn'>ยืนยัน</Button>
                    </div>
                </Form>
            </div>
            {/* } */}
        </>
    )
}
