import React,{useEffect, useState} from 'react'
import { Button, Form, Input, Divider } from 'antd';
import { AiOutlineSync, AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import responseHandleSrv from '../../../services/responseHandleSrv';
import axios from '../../../axios';
import Api from '../../../constants/Api';
import { authBasicHeader } from '../../../services/auth-header';
import otpSrv from '../../../services/otpSrv';

export default function OtpSale(props) {
    const { onNextPage, onPrevPage, profileValue, lineProfile } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [checkLink, setCheckLink] = useState(false);
    const [checkBtn, setCheckBtn] = useState(true);
    const [isOverlay, setOverlay] = useState(false);

    // useEffect(() => {
    //     sendOtp(profileValue.MobileNo);
    // }, []);
    const onFinish = (values) => {
        verifyOtp(profileValue.MobileNo, values.otp);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const replaceMobileNo = (mobileNo) => {
        let str = "XXX-XXX-"+mobileNo.slice(6,10);
        return str;
    }

    const sendOtp = () => {
        setCheckLink(true)
        let mobileNo = profileValue.MobileNo;
        if(mobileNo !== undefined)
        {
            (async () => {
                // setIsLoading(true)
                await otpSrv.sendOtp(mobileNo).then(response => {
                    if (response.data.success) {
                        responseHandleSrv.handleMsg('ส่ง OTP สำเร็จ');
                        let res = response.data.data;
                        setTimeout(() => {
                            setCheckLink(false)
                        }, 10000);
                    }
                }).catch(err => {
                    setIsLoading(false)
                    responseHandleSrv.handleError(err);
                });
            })();
        }

    }

    const verifyOtp = (mobileNo, pinCode) => {
        (async () => {
            // setIsLoading(true)
            await otpSrv.verifyOtp(mobileNo, pinCode).then(response => {
                if (response.data.success) {
                    let res = response.data.data;
                    activateSale();
                }
            }).catch(err => {
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    }

    const activateSale = () => {

        let params = {
            MobileNo: profileValue.MobileNo,
            LineId: lineProfile.userId
        };
        profileValue.IsActive = profileValue.IsActive === 1 ? true : false;
        (async () => {
            setOverlay(true);
            await axios.put(`${Api.SALE_ACTIVATE + profileValue.Id}`, params, {
                headers: authBasicHeader()
            }).then(response => {
                if (response.data.success) {
                    getSapToken(profileValue);
                    addUserToRichMenu(profileValue.SaleTypeId, lineProfile.userId);
                }

            }).catch(err => {
                setIsLoading(false)
                setOverlay(false);
                responseHandleSrv.handleError(err);
            });
        })();
    }
    const addUserToRichMenu = (SaleTypeId, lineId) => {
        let data = {
            SaleTypeId,
            LineId: lineId
        };
        (async () => {
            await axios.post(`${Api.UTILITY_SALE_ADD_USER_RICHMENU}`, data, {
                headers: authBasicHeader()
            }).then(response => {
                if (response.data.success) {

                }

            })
        })();
    }

    const saleEmployeeSap = (body) => {

        (async () => {
            await axios.post(`${Api.ACT_SALE_EMPLOYEE}`, body, {
                headers: authBasicHeader()
            }).then(response => {
                if (response.data.success) {
                    let res = response.data.data;
                    if (res.status_code === "200" && res.message_id === "1501") {
                        // responseHandleSrv.handleSuccess(response);
                        onNextPage();
                    } else {
                        responseHandleSrv.handleErrorMsg('เกิดข้อผิดพลาด', res.message);
                        setOverlay(false);
                    }
                }
            }).catch(err => {
                console.log(err);
                setIsLoading(false)
                setOverlay(false);
                responseHandleSrv.handleError(err);
            });
        })();
    };

    const getSapToken = (body) => {
        (async () => {
            await axios.get(`${Api.ACT_TOKEN}`, {
                headers: authBasicHeader()
            }).then(response => {
                if (response.data.success) {
                    console.log('getSapToken', response);
                    let res = response.data.data;
                    body.token = res.token;
                    saleEmployeeSap(body)
                    
                }
            }).catch(err => {
                console.log(err);
                // responseHandleSrv.handleError(err);
            });
        })();
    };

    const onChangeOtp = (e) => {

        if (e.target.value) {
            if (e.target.value.length === 4) {
                setCheckBtn(false);
            } else {
                setCheckBtn(true);
            }
        } else {
            setCheckBtn(true);
        }

    };

    return (
        <div>
            <div id="overlay" style={{ display: isOverlay === true ? "block" : "none" }}><div class="text-overlay-center">กำลังดำเนินการ...</div></div>
            <div className='' style={{ padding: '5%' }}>
                <div className='dfc-center'>
                    <div>ระบบได้ส่งรหัส OTP </div>
                    <div>ไปยังเบอร์โทรศัพท์มือถือ {replaceMobileNo(profileValue.MobileNo)}</div>
                </div>
                <Divider />
                <div>กรุณากรอกรหัส OTP ที่ได้รับ</div>
                <br />
                <Form
                    onFinish={onFinish}
                    onFinishFailed={responseHandleSrv.onFinishFailed}
                >
                    <Form.Item 
                        name="otp"
                        rules={[
                            {
                                required: true,
                                message: 'กรุณากรอก OTP'
                            }
                        ]}

                    >
                        <Input placeholder='กรอก OTP' maxLength={4} onChange={onChangeOtp} />
                    </Form.Item>
                    <div className='df-centerY'>
                        <a className='df-centerY' style={{ marginBottom: '25px' }} disabled={checkLink === true ? true : false} onClick={sendOtp} >ขอรหัส OTP อีกครั้ง &nbsp;<AiOutlineSync /></a>
                        <Button type='primary' htmlType='submit' style={{ width: '100%' }} disabled={checkBtn}>ยืนยัน</Button>
                    </div>
                </Form>         
                <br />
                <div className='df-spaceBtw'>
                    {/* <Button className='df-centerY' shape='round' onClick={() => onPrevPage()}><AiOutlineArrowLeft />&nbsp;&nbsp;Back</Button> */}
                    {/* <Button className='df-centerY' shape='round' onClick={() => onNextPage()}>Next&nbsp;&nbsp;<AiOutlineArrowRight /></Button> */}
                </div>
            </div>

        </div>
    )
}
