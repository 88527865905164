import axios from "../axios";
import Api from "../constants/Api";
import { authHeader } from "./auth-header";
import moment from "moment";


const register = (data) => {
  return axios.post(`${Api.USER_CREATE}`, data, { headers: authHeader() }).then(handleResponse);
};

const update = (code, data) => {
  return axios.put(`${Api.USER_UPDATE + code}`, data, { headers: authHeader() }).then(handleResponse);
};

const _delete = (code) => {
  return axios.delete(`${Api.USER_DELETE + code}`, { headers: authHeader() }).then(handleResponse);
};

const changePass = (code, data) => {
  return axios.put(`${Api.USER_CHANGE_PASSWORD + code}`, { headers: authHeader() }).then(handleResponse);
};

const closeCarPeriod = () => {
  let data1 = { UpdateBy: 0, Date: moment().format() }
  return axios.post(`${Api.MEDIA_CAR_CLOSE_PERIOD}`, data1, { headers: authHeader() }).then(handleResponse);
};

const closeStorePeriod = () => {
  let data1 = { UpdateBy: 0, Date: moment().format() }
  return axios.post(`${Api.MEDIA_STORE_CLOSE_PERIOD}`, data1, { headers: authHeader() }).then(handleResponse);
};

const getGovernmentProject = () => {
  
    return  axios.get(`${Api.MASTER_GOVERNMENT_PROJECT}`, {
          headers: authHeader()
      });
    
};


const login = (username, password) => {
  return axios
    .post(`${Api.AUTH_LOGIN}`, {
      username,
      password,
    }).then(handleResponse)
    .then((user) => {
      if (user) {
        sessionStorage.user = JSON.stringify(user);
      }

      return user;
    });
};

const logout = () => {
  // sessionStorage.removeItem("user")
  // sessionStorage.removeItem("mainmenu")
  // sessionStorage.removeItem("keyTab")
  localStorage.removeItem("user");
  localStorage.removeItem("mainmenu");
  // localStorage.removeItem("submenu");
  // localStorage.removeItem("keyTab");
  const root = window.location.origin;
 
  window.location.href = root;
};

const getCurrentUser = () => {
  // const userSession = sessionStorage.length === 0 ? null : JSON.parse(sessionStorage.user)

  const userSession = localStorage.user !== undefined ? JSON.parse(localStorage.user) : null
  return userSession;
};

const getAll = () => {
  return axios.get(`${Api.USER_LIST}`, { headers: authHeader() }).then(handleResponse);
};

const getByCode = (code) => {
  return axios.get(`${Api.USER_GET_BY_CODE}`, {
    headers: authHeader(),
    params: {
      code: code
    }
  }).then(handleResponse);
};

const getByName = (name) => {
  return axios.get(`${Api.USER_GET_BY_NAME}`, {
    headers: authHeader(),
    params: {
      name: name
    }
  }).then(handleResponse);
};

const search = (code, name) => {
  return axios.get(`${Api.USER_SEARCH}`, {
    headers: authHeader(),
    params: {
      code: code,
      name: name
    }
  }).then(handleResponse);
};

const handleResponse = (response) => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        //location.reload();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}





export default {
  register,
  update,
  _delete,
  changePass,
  login,
  logout,
  getCurrentUser,
  getAll,
  getByCode,
  getByName,
  search,
  closeCarPeriod,
  closeStorePeriod,
  getGovernmentProject
};