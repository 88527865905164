import axios from 'axios';
const root = window.location.origin;

let api_url = "";

switch(root) {
  case process.env.REACT_APP_URL_UAT:
    api_url = process.env.REACT_APP_API_UAT;
    break;
  default:
    api_url = process.env.REACT_APP_API_LOCAL;
}
console.log('api_url',api_url);
const instance = axios.create({
  baseURL: api_url,
  headers: {
    'Content-Type': 'application/json'
  }
});


export default instance;