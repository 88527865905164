const  authHeader = () => {
    // let user = sessionStorage.length === 0 ? null : JSON.parse(sessionStorage.user);
    let user = localStorage.length === 0 ? null : JSON.parse(localStorage.user);
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

const authBasicHeader = () => {
    return { 'Authorization': 'Basic ' + process.env.REACT_APP_HEADER_BASIC };
    
}

export {
    authHeader,
    authBasicHeader

}
