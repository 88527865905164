import React, { useState } from 'react';
import axios from '../../../axios';
import { Form, Input, Button, notification } from 'antd';
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";
import { Grid } from '@mui/material';
import Api from '../../../constants/Api';
import responseHandleSrv from '../../../services/responseHandleSrv';
import NewPassIllust from '../../../styles/images/newPassIllust.svg'

export default function ChangePassword() {

  const [isLoading, setIsLoading] = useState(false);
  const root = window.location.origin;

  const onFinish = (values) => {
    setIsLoading(true);
    if (values.username && values.newPassword) {
      (async () => {
        await axios.post(
          `${Api.AUTH_CHANGE_PASSWORD}`, {
          username: values.username,
          newPassword: values.newPassword
        }).then((response) => {
          if (response.data.success === true) {
            if (typeof (Storage) !== "undefined") {
              localStorage.user = JSON.stringify(response.data.data);
            }
            window.location.href = `${root}/`;
          }
        }).catch(error => {
          setIsLoading(false);
          responseHandleSrv.handleError(error);
        });
      })();
    }
  };
  const onFinishFailed = (values) => {
    const [{ errors }] = values.errorFields
    notification.error({
      message: 'กรอกข้อมูลไม่ครบ',
      description: errors,
    });

  };

  return (
    <>
      {
        isLoading === true ?
          <div className='loadingOverlay'>
            <div className='df-center'><div className="spinLoader" /></div>
          </div> :
          <div className='df-centerXY loginPage' >
            <Grid container>
              <Grid item xs={12} sm={12} md={6} className='newPassDisplay1' >
                <img src={NewPassIllust} className='newPassIllust' />
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='newPassContent' >
                <div className='loginHeader'>Create New Password</div>
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout='vertical'
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Form.Item
                        name='username'
                        label='Username'
                        rules={[
                          {
                            required: true,
                            message: 'กรอก Username!',
                          }
                        ]}
                      >
                        <Input className='loginForm' prefix={<AiOutlineUser />} size='large' placeholder='Username' />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={12}>
                      <Form.Item
                        name='newPassword'
                        label='New Password'
                        rules={[
                          {
                            required: true,
                            message: 'กรอก New Password',
                          }
                        ]}
                      >
                        <Input.Password className='loginForm' prefix={<AiOutlineLock />} size='large' placeholder='New Password' />
                      </Form.Item>
                    </Grid>
                    <Grid item xs={12} className='newPassBtn'>
                      <Button type='primary' size='large' shape='round' style={{ padding: '0px 50px ' }} htmlType="submit" className='primaryRoundBtnAnimate'>Reset Password</Button>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className='newPassDisplay2' >
                <img src={NewPassIllust} className='newPassIllust' />
              </Grid>
            </Grid>
          </div>
      }
    </>

  )
}
