import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './services/configureStore';
import { BrowserRouter as Router } from 'react-router-dom';
const store = configureStore()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
// ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router basename="/">
      <App />
    </Router>

  </Provider>
  // </React.StrictMode>
  // ,
  // document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
