import React, { useEffect, useState } from 'react'
import HeaderApp from '../../header/HeaderApp'
import MenuApp from '../../constants/MenuApp'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../services/action'
import { Layout, Breadcrumb, Card, Form, Input, Select, Button, Space, Table, Tag, Modal, Switch, Pagination, InputNumber } from 'antd'
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import { RiEditCircleFill, RiAddCircleFill } from "react-icons/ri";
import { Grid } from '@mui/material';
import Api from '../../constants/Api';
import responseHandleSrv from '../../services/responseHandleSrv';
import axios from '../../axios';
import { authHeader } from '../../services/auth-header';
import { tagLabelActive } from '../../services/function';

export default function Company() {
    const { Option } = Select;
    const { confirm } = Modal;
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [data, setData] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [subDistrictList, setSubDistrictList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOverlay, setOverlay] = useState(false);
    const [valueID, setValueID] = React.useState('');
    const [form] = Form.useForm();
    const [formadd] = Form.useForm();
    const [formedit] = Form.useForm();
    const [pageData, setPageData] = useState({
        currentPage: 1,
        pageSize: 10,
        totalPage: '',
        previousPage: '',
        nextPage: '',
        totalcount: '',
        goToPage: ''
    });
    const [filter, setFilter] = useState({
        search: '',
        SubDistrictId: '',
        DistrictId: '',
        ProvinceId: '',
        RegionId: ''
    });
    useEffect(() => {
        onClear();
        getRegionList();
        getProvinceList();
        getDistrictList();
        getSubDistrictList();

    }, []);
    const getData = (page, pageSize, search, SubDistrictId, DistrictId, ProvinceId, RegionId) => {
        (async () => {
            setIsLoading(true)
            await axios.get(`${Api.COMPANY_SEARCH_LIST}`, {
                headers: authHeader(),
                params: {
                    search,
                    SubDistrictId,
                    DistrictId,
                    ProvinceId,
                    RegionId,
                    page,
                    skip: pageSize,
                }
            }).then(response => {
                if (response.data.success) {
                    let data = response.data.data.result;
                    setData(data);

                    let dd = {
                        ...pageData,
                        "currentPage": page,
                        "pageSize": pageSize,
                        "totalPage": response.data.totalPage,
                        "previousPage": response.data.previousPage,
                        "nextPage": response.data.nextPage,
                        "totalcount": response.data.data.totalCount,
                        "goToPage": ''
                    }

                    setPageData(dd);
                }
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    };
    const onClear = () => {

        form.resetFields();

        getData(1, pageData.pageSize, '', '', '', '', '');

        let dd = {
            ...pageData,
            "currentPage": 1,
            "pageSize": pageData.pageSize,
            "totalPage": '',
            "previousPage": '',
            "nextPage": '',
            "totalcount": ''
        }
        setPageData(dd);

        let ft = {
            ...filter,
            "search": '',
            'SubDistrictId': '',
            'DistrictId': '',
            'ProvinceId': '',
            'RegionId': ''
        }
        setFilter(ft);
    }
    const onChangePage = (page, pageSize) => {

        let pp = {
            ...pageData,
            "currentPage": page,
            "pageSize": pageSize
        }
        setPageData(pp);

        getData(page, pageSize, filter.search, filter.SubDistrictId, filter.DistrictId, filter.ProvinceId, filter.RegionId);

    }
    const onFinish = (values) => {

        let search = values.search !== undefined ? values.search : '';
        let SubDistrictId = values.SubDistrictId !== undefined ? values.SubDistrictId : '';
        let DistrictId = values.DistrictId !== undefined ? values.DistrictId : '';
        let ProvinceId = values.ProvinceId !== undefined ? values.ProvinceId : '';
        let RegionId = values.RegionId !== undefined ? values.RegionId : '';
        let ft = {
            ...filter,
            "search": search,
            "SubDistrictId": SubDistrictId,
            "DistrictId": DistrictId,
            "ProvinceId": ProvinceId,
            "RegionId": RegionId
        }

        setFilter(ft);

        getData(1, pageData.pageSize, search, SubDistrictId, DistrictId, ProvinceId, RegionId);

    };
    const showModdalCreate = () => {
        setVisibleCreate(true);
        formadd.resetFields();
        setAddressList([]);
    }
    const showModalEdit = (record) => {
        setVisibleEdit(true);
        setValueID(record.Id);
        formedit.resetFields();

        if (record.PostCode) {
            getAddressByPostcode(record.PostCode);
        }
        formedit.setFieldsValue({
            'Code': record.Code ? record.Code : '',
            'Name': record.Name ? record.Name : '',
            'Telephone': record.Telephone ? record.Telephone : '',
            'Address': record.Address ? record.Address : '',
            'PostCode': record.PostCode ? record.PostCode : '',
            'SubDistrictId': record.SubDistrictId ? record.SubDistrictId : '',
            'DistrictId': record.DistrictId ? record.DistrictId : '',
            'DistrictName': record.DistrictName ? record.DistrictName : '',
            'ProvinceId': record.ProvinceId ? record.ProvinceId : '',
            'ProvinceName': record.ProvinceName ? record.ProvinceName : '',
            'RegionId': record.RegionId ? record.RegionId : '',
            'RegionName': record.RegionName ? record.RegionName : '',
            'TaxNo': record.TaxNo ? record.TaxNo : '',
            'ContactName': record.ContactName ? record.ContactName : '',
            'IsActive': record.IsActive === 1 ? true : false,

        });
    }
    const showModalDelete = (values) => {
        confirm({
            title: 'คุณแน่ใจที่จะลบรายการนี้?',
            okText: 'ใช่',
            cancelText: 'ไม่',
            okType: 'danger',
            centered: true,
            closable: true,
            okButtonProps: { className: 'dangerOutlAnimate' },
            cancelButtonProps: { className: 'radiusBtn' },
            onOk() {
                deleteCompany(values);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const deleteCompany = (values) => {

        (async () => {
            await axios.delete(`${Api.COMPANY_UPDATE + values.Id}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    onClear();
                }

            }).catch(err => {
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    }

    const handleOk = () => {
        setVisibleCreate(false);
    }
    const handleCancel = () => {
        setVisibleCreate(false);
        formadd.resetFields();
        onClear();
    }
    const handleCancelEdit = () => {
        setVisibleEdit(false);
        setValueID('');
        setAddressList([]);
        formedit.resetFields();
        onClear();
    }

    const columns = [
        {
            title: 'รหัส',
            dataIndex: 'Code',
            key: 'Code',
            width: 170,
            align: 'center'
        },
        {
            title: 'ชื่อ',
            dataIndex: 'Name',
            key: 'Name',
            width: 250,
            align: 'center'
        },
        {
            title: 'ที่อยู่',
            dataIndex: 'Address',
            key: 'Address',
            align: 'center',
            width: '170',
        },
        {
            title: 'ตำบล',
            dataIndex: 'SubDistrictName',
            key: 'SubDistrictName',
            width: 150,
            align: 'center'
        },
        {
            title: 'อำเภอ',
            key: 'DistrictName',
            dataIndex: 'DistrictName',
            width: 150,
            align: 'center'
        },
        {
            title: 'จังหวัด',
            key: 'ProvinceName',
            dataIndex: 'ProvinceName',
            align: 'center',
            width: 150,
        },
        {
            title: 'ภาค',
            key: 'RegionName',
            dataIndex: 'RegionName',
            align: 'center',
            width: 130,
        },
        {
            title: 'เบอร์โทรศัพท์',
            key: 'Telephone',
            dataIndex: 'Telephone',
            align: 'center',
            width: 150,
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'ชื่อผู้ติดต่อ',
            key: 'ContactName',
            dataIndex: 'ContactName',
            align: 'center',
            width: 170,
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'สถานะการใช้งาน',
            key: 'IsActive',
            dataIndex: 'IsActive',
            align: 'center',
            width: 140,
            render: (text, record) => (
                <>
                    {
                        text === 0 ? <div><Tag color={tagLabelActive[record.IsActive]}>ปิดการใช้งาน</Tag></div>
                            : <div><Tag color={tagLabelActive[record.IsActive]}>เปิดการใช้งาน</Tag></div>
                    }

                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: 110,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="text" shape="circle" icon={<AiOutlineEdit />} onClick={() => showModalEdit(record)} />
                    <Button type="text" shape="circle" danger icon={<AiOutlineDelete />} onClick={() => showModalDelete(record)} />
                </Space>
            ),
        },
    ];

    const getRegionList = () => {
        (async () => {
            await axios.get(`${Api.REGION_LIST}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    setRegionList(response.data.data);
                }
            })
        })();
    };
    const getProvinceList = () => {
        (async () => {
            await axios.get(`${Api.PROVINCE_LIST}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    setProvinceList(response.data.data);
                }
            })
        })();
    };
    const getDistrictList = () => {
        (async () => {
            await axios.get(`${Api.DISTRICT_LIST}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    setDistrictList(response.data.data);
                }
            })
        })();
    };
    const getSubDistrictList = () => {
        (async () => {
            await axios.get(`${Api.SUB_DISTRICT_LIST}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    setSubDistrictList(response.data.data);
                }
            })
        })();
    };
    const getAddressByPostcode = (postcode) => {
        (async () => {
            await axios.get(`${Api.ADDRESS_BY_POSTCODE + postcode}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    setAddressList(response.data.data);
                }
            }).catch(err => {
                setAddressList([]);
                responseHandleSrv.handleError(err);
            })
        })();
    };
    const postCodeChange = (value, e) => {
        if (e.target.value) {
            clearAddress(value);
        }
        else {
            setAddressList([]);
            clearAddress(value);
        }
    };

    const clearAddress = (form) => {
        if (form === 'add') {
            formadd.resetFields([
                'SubDistrictId',
                'DistrictId',
                'DistrictName',
                'ProvinceId',
                'ProvinceName',
                'RegionId',
                'RegionName'
            ]);
        }
        else {
            formedit.resetFields([
                'SubDistrictId',
                'DistrictId',
                'DistrictName',
                'ProvinceId',
                'ProvinceName',
                'RegionId',
                'RegionName'
            ]);
        }
    }

    const postCodeBlur = (value, e) => {

        if (e.target.value) {
            setAddressList([]);
            clearAddress(value);

            if (e.target.value.length !== 5) {
                // responseHandleSrv.handleErrorMsg('แจ้งเตือน', 'กรุณากรอกรหัสไปรษณีย์มีความยาว 5 หลัก');
                return;
            }

            let result = String(e.target.value).match(/^[0-9.]+$/);
            if (result == null) {
                // responseHandleSrv.handleErrorMsg('แจ้งเตือน', 'กรุณากรอกรหัสไปรษณีย์เป็นตัวเลขเท่านั้น');
                return;
            }

            getAddressByPostcode(e.target.value, '');
        }
        else {
            setAddressList([]);
            clearAddress(value);
        }
    };
    const setValueAddress = (form, value) => {
        if (form === 'add') {
            formadd.setFieldsValue({
                'DistrictId': value.DistrictId,
                'DistrictName': value.DistrictnameTh,
                'ProvinceId': value.ProvinceId,
                'ProvinceName': value.ProvinceNameTh,
                'RegionId': value.RegionId,
                'RegionName': value.RegionNameTh
            });
        }
        else {
            formedit.setFieldsValue({
                'DistrictId': value.DistrictId,
                'DistrictName': value.DistrictnameTh,
                'ProvinceId': value.ProvinceId,
                'ProvinceName': value.ProvinceNameTh,
                'RegionId': value.RegionId,
                'RegionName': value.RegionNameTh
            });
        }
    };
    const onChangeSubDistrict = (form, e) => {
        if (e) {
            let find = addressList.find(x => x.SubDistrictId === e);
            setValueAddress(form, find)
        }
        else { //not value
            clearAddress(form)
        }
    };
    const onFinishAdd = (values) => {

        let params = {
            Code: values.Code !== undefined ? values.Code : null,
            Name: values.Name !== undefined ? values.Name : '',
            Address: values.Address !== undefined ? values.Address : '',
            SubDistrictId: values.SubDistrictId !== undefined ? values.SubDistrictId : '',
            DistrictId: values.DistrictId !== undefined ? values.DistrictId : '',
            ProvinceId: values.ProvinceId !== undefined ? values.ProvinceId : null,
            RegionId: values.RegionId !== undefined ? values.RegionId : null,
            PostCode: values.PostCode !== undefined ? values.PostCode : null,
            Telephone: values.Telephone !== undefined ? values.Telephone : null,
            TaxNo: values.TaxNo !== undefined ? values.TaxNo : null,
            ContactName: values.ContactName !== undefined ? values.ContactName : null,
            IsActive: values.IsActive !== undefined ? values.IsActive : false
        };

        (async () => {
            setIsLoading(true);
            setOverlay(true);
            await axios.post(`${Api.COMPANY_CREATE}`, params, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {

                    responseHandleSrv.handleSuccess(response);
                    handleCancel();
                    setIsLoading(false);
                    setOverlay(false);

                }
            }).catch(err => {
                setIsLoading(false);
                setOverlay(false);
                responseHandleSrv.handleError(err);
            });
        })();
    };
    const onFinishEdit = (values) => {

        let params = {
            Code: values.Code !== undefined ? values.Code : null,
            Name: values.Name !== undefined ? values.Name : '',
            Address: values.Address !== undefined ? values.Address : '',
            SubDistrictId: values.SubDistrictId !== undefined ? values.SubDistrictId : '',
            DistrictId: values.DistrictId !== undefined ? values.DistrictId : '',
            ProvinceId: values.ProvinceId !== undefined ? values.ProvinceId : null,
            RegionId: values.RegionId !== undefined ? values.RegionId : null,
            PostCode: values.PostCode !== undefined ? values.PostCode : null,
            Telephone: values.Telephone !== undefined ? values.Telephone : null,
            TaxNo: values.TaxNo !== undefined ? values.TaxNo : null,
            ContactName: values.ContactName !== undefined ? values.ContactName : null,
            IsActive: values.IsActive !== undefined ? values.IsActive : false
        };

        (async () => {
            setIsLoading(true);
            setOverlay(true);
            await axios.put(`${Api.COMPANY_UPDATE + valueID}`, params, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {

                    responseHandleSrv.handleSuccess(response);
                    handleCancelEdit();
                    setIsLoading(false);
                    setOverlay(false);

                }
            }).catch(err => {
                setIsLoading(false);
                setOverlay(false);
                responseHandleSrv.handleError(err);
            });
        })();
    };


    return (
        <>
        <div id="overlay" style={{display: isOverlay === true ? "block" : "none"}}><div class="text-overlay-center">Loading...</div></div>
            <div className="page-container">
                <HeaderApp />
                <div className='pageSider'>
                    <MenuApp />
                    <div className="main-content">
                        {/* modal create */}
                        <Modal
                            title={<div className='modalHeader'><RiAddCircleFill size='20px' />&nbsp;&nbsp;Add Company</div>}
                            open={visibleCreate}
                            footer={false}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            centered='true'
                            destroyOnClose={true}
                            className='modalCreate'
                        >
                            <Form
                                layout='vertical'
                                form={formadd}
                                name="add-company"
                                onFinish={onFinishAdd}
                                onFinishFailed={responseHandleSrv.onFinishFailed}
                                initialValues={{
                                    'IsActive': true
                                }}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='รหัสบริษัท'
                                            name='Code'
                                            rules={[{ required: true, message: 'กรอกรหัสบริษัท' }]}
                                        >
                                            <Input placeholder='กรอกรหัสบริษัท' maxLength={10} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ชื่อบริษัท'
                                            name='Name'
                                            rules={[{ required: true, message: 'กรอกชื่อบริษัท' }]}
                                        >
                                            <Input placeholder='กรอกชื่อบริษัท' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='เบอร์โทรศัพท์'
                                            name='Telephone'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรอกเบอร์โทรศัพท์'
                                                },
                                                {
                                                    pattern: /^[0][0-9]{8,9}$\b/,
                                                    message: 'เบอร์โทรศัพท์ไม่ถูกต้อง'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='กรอกเบอร์โทรศัพท์' maxLength="10" minLength="9" />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ที่อยู่'
                                            name='Address'
                                            rules={[{ required: true, message: 'กรอกที่อยู่' }]}
                                        >
                                            <Input placeholder='กรอกที่อยู่' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='รหัสไปรษณีย์'
                                            name='PostCode'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรอกรหัสไปรษณีย์'
                                                },
                                                {
                                                    pattern: /^[\d]{5}$/,
                                                    message: 'รหัสไปรษณีย์มีความยาว 5 หลัก และเป็นตัวเลขเท่านั้น'
                                                }

                                            ]}
                                        >
                                            <Input onBlur={postCodeBlur.bind(this, 'add')} onChange={postCodeChange.bind(this, 'add')} placeholder='กรอกรหัสไปรษณีย์' maxLength={5} minLength={5} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ตำบล'
                                            name='SubDistrictId'
                                            rules={[{ required: true, message: 'เลือกตำบล' }]}
                                        >
                                            <Select
                                                placeholder='เลือกตำบล'
                                                disabled={!addressList || addressList.length === 0}
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                                onChange={onChangeSubDistrict.bind(this, 'add')}
                                            >
                                                {addressList && addressList.map((s) => <Option value={s.Id} key={s.SubDistrictId}>{s.SubdistrictTh}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item name='DistrictId' hidden={true} />
                                        <Form.Item
                                            label='อำเภอ'
                                            name='DistrictName'
                                        >
                                            <Input placeholder='อำเภอ' disabled />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item name='ProvinceId' hidden={true} />
                                        <Form.Item
                                            label='จังหวัด'
                                            name='ProvinceName'
                                        >
                                            <Input placeholder='จังหวัด' disabled />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item name='RegionId' hidden={true} />
                                        <Form.Item
                                            label='ภาค'
                                            name='RegionName'
                                        >
                                            <Input placeholder='ภาค' disabled />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='TaxNo'
                                            name='TaxNo'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรอก TaxNo'
                                                },
                                                {
                                                    pattern: /^[\d]{13}$/,
                                                    message: 'Tax No. มีความยาว 13 หลัก และเป็นตัวเลขเท่านั้น'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='กรอก TaxNo' maxLength={13} minLength={13} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ชื่อผู้ติดต่อ'
                                            name='ContactName'
                                            rules={[{ required: true, message: 'กรอกชื่อผู้ติดต่อ' }]}
                                        >
                                            <Input placeholder='กรอกชื่อผู้ติดต่อ' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='สถานะการใช้งาน'
                                            name='IsActive'
                                            valuePropName='checked'
                                        >
                                            <Switch defaultChecked checkedChildren='เปิด' unCheckedChildren='ปิด'></Switch>
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                                <div className='df-flexEnd'>
                                    <Button onClick={() => handleCancel()} className='radiusBtn'>Cancel</Button>&nbsp;
                                    <Button type='primary' htmlType='submit' className='primaryBtnAnimate'>Save</Button>
                                </div>
                            </Form>
                        </Modal>

                        {/* modal edit */}
                        <Modal
                            title={<div className='modalHeader'><RiEditCircleFill size='20px' />&nbsp;&nbsp;Edit Company</div>}
                            open={visibleEdit}
                            footer={false}
                            onOk={handleOk}
                            onCancel={handleCancelEdit}
                            centered='true'
                            destroyOnClose={true}
                            className='modalCreate'
                        >
                            <Form
                                layout='vertical'
                                form={formedit}
                                name="edit-company"
                                onFinish={onFinishEdit}
                                onFinishFailed={responseHandleSrv.onFinishFailed}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='รหัสบริษัท'
                                            name='Code'
                                            rules={[{ required: true, message: 'กรอกรหัสบริษัท' }]}
                                        >
                                            <Input placeholder='กรอกรหัสบริษัท' maxLength={10} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ชื่อบริษัท'
                                            name='Name'
                                            rules={[{ required: true, message: 'กรอกชื่อบริษัท' }]}
                                        >
                                            <Input placeholder='กรอกชื่อบริษัท' />
                                        </Form.Item>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='เบอร์โทรศัพท์'
                                            name='Telephone'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรอกเบอร์โทรศัพท์'
                                                },
                                                {
                                                    pattern: /^[0][0-9]{8,9}$\b/,
                                                    message: 'เบอร์โทรศัพท์ไม่ถูกต้อง'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='กรอกเบอร์โทรศัพท์' maxLength="10" minLength="9" />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ที่อยู่'
                                            name='Address'
                                            rules={[{ required: true, message: 'กรอกที่อยู่' }]}
                                        >
                                            <Input placeholder='กรอกที่อยู่' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='รหัสไปรษณีย์'
                                            name='PostCode'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรอกรหัสไปรษณีย์'
                                                },
                                                {
                                                    pattern: /^[\d]{5}$/,
                                                    message: 'รหัสไปรษณีย์มีความยาว 5 หลัก และเป็นตัวเลขเท่านั้น'
                                                }

                                            ]}
                                        >
                                            <Input onBlur={postCodeBlur.bind(this, 'edit')} onChange={postCodeChange.bind(this, 'edit')} placeholder='กรอกรหัสไปรษณีย์' maxLength={5} minLength={5} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ตำบล'
                                            name='SubDistrictId'
                                            rules={[{ required: true, message: 'เลือกตำบล' }]}
                                        >
                                            <Select
                                                placeholder='เลือกตำบล'
                                                disabled={!addressList || addressList.length === 0}
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                                onChange={onChangeSubDistrict.bind(this, 'edit')}
                                            >
                                                {addressList && addressList.map((s) => <Option value={s.Id} key={s.SubDistrictId}>{s.SubdistrictTh}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item name='DistrictId' hidden={true} />
                                        <Form.Item
                                            label='อำเภอ'
                                            name='DistrictName'
                                        >
                                            <Input placeholder='อำเภอ' disabled />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item name='ProvinceId' hidden={true} />
                                        <Form.Item
                                            label='จังหวัด'
                                            name='ProvinceName'
                                        >
                                            <Input placeholder='จังหวัด' disabled />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item name='RegionId' hidden={true} />
                                        <Form.Item
                                            label='ภาค'
                                            name='RegionName'
                                        >
                                            <Input placeholder='ภาค' disabled />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='TaxNo'
                                            name='TaxNo'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรอก TaxNo'
                                                },
                                                {
                                                    pattern: /^[\d]{13}$/,
                                                    message: 'Tax No. มีความยาว 13 หลัก และเป็นตัวเลขเท่านั้น'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='กรอก TaxNo' maxLength={13} minLength={13} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ชื่อผู้ติดต่อ'
                                            name='ContactName'
                                            rules={[{ required: true, message: 'กรอกชื่อผู้ติดต่อ' }]}
                                        >
                                            <Input placeholder='กรอกชื่อผู้ติดต่อ' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='สถานะการใช้งาน'
                                            name='IsActive'
                                            valuePropName='checked'
                                        >
                                            <Switch defaultChecked checkedChildren='เปิด' unCheckedChildren='ปิด'></Switch>
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                                <div className='df-flexEnd'>
                                    <Button onClick={() => handleCancelEdit()} className='radiusBtn'>Cancel</Button>&nbsp;
                                    <Button type='primary' htmlType='submit' className='primaryBtnAnimate'>Save</Button>
                                </div>
                            </Form>
                        </Modal>

                        <div className='pdPage'>
                            <div className='buttonHeader ' >
                                <div>
                                    <b className='pageTitle'>Company</b>
                                    <Breadcrumb className='breadcrumbStyle' items={[{ title: 'Company', href: '/backend/company' }]} />
                                </div>
                                <Button type='primary' onClick={() => showModdalCreate()} className='df-centerY primaryBtnAnimate'><AiOutlinePlus />&nbsp;&nbsp;Add Company</Button>
                            </div>

                            <div className='searchCard'>
                                <Form
                                    layout='vertical'
                                    form={form}
                                    name="advanced_search"
                                    onFinish={onFinish}
                                >
                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item label='ค้นหา' name='search'>
                                                <Input placeholder='รหัส ชื่อ นามสกุล เบอร์โทรศัพท์'></Input>
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item
                                                label='ตำบล'
                                                name="SubDistrictId"
                                            >
                                                <Select
                                                    placeholder='ตำบล'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {subDistrictList && subDistrictList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item
                                                label='อำเภอ'
                                                name="DistrictId"
                                            >
                                                <Select
                                                    placeholder='อำเภอ'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {districtList && districtList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item
                                                label='จังหวัด'
                                                name="ProvinceId"
                                            >
                                                <Select
                                                    placeholder='จังหวัด'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {provinceList && provinceList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item
                                                label='ภาค'
                                                name="RegionId"
                                            >
                                                <Select
                                                    placeholder='ภาค'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {regionList && regionList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} className='df-centerY'>Result: {pageData.totalcount} {pageData.totalcount > 0 ? (pageData.totalcount === 1 ? "item" : "items") : ""}</Grid>
                                        <Grid item xs={12} sm={6} className='searchBtnOrg'>
                                            <Button type='primary' htmlType='submit' className='df-centerY primaryBtnAnimate'><AiOutlineSearch />&nbsp;&nbsp;ค้นหา</Button>&nbsp;
                                            <Button danger className='df-centerY dangerOutlAnimate' onClick={() => onClear()}><AiOutlineDelete />&nbsp;&nbsp;เคลียร์</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </div>
                            <div>
                                {isLoading === true ?
                                    <div className="linearloader" /> :
                                    <>
                                        <Table
                                            className='headerTable-color listData-card'
                                            columns={columns}
                                            dataSource={data}
                                            pagination={false}
                                            scroll={{ x: 2200 }}
                                            rowKey={record => record.Id}
                                            rowClassName={'tableBody'}
                                        />
                                        <Pagination
                                            total={pageData.totalcount}
                                            showSizeChanger
                                            showQuickJumper
                                            current={pageData.currentPage}
                                            defaultPageSize={pageData.pageSize}
                                            onChange={onChangePage}
                                            responsive={true}
                                            className="tableBottom"
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
