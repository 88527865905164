const Change_Collape = 'main/Change_Collap'
const Change_Toggle = 'main/Change_Toggle'

function changecollape() {

    return {
        type: Change_Collape,
    }
}
function changeToggle() {

    return {
        type: Change_Toggle,
    }
}


export {
    changecollape,
    changeToggle,
    Change_Collape,
    Change_Toggle,
}