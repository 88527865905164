import React, { useState } from 'react';
import { Spin, Result, Button } from 'antd';

export default function MsgAdmin() {
    return (
        <div className='dfc-centerXYpage'>
            <Result
                status="warning"
                title="หากต้องการเข้าใช้งาน กรุณาติดต่อ admin"
            />
        </div>
    )
}
