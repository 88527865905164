import React, { useEffect, useState } from 'react';
import SearchSale from './SearchSale';
import VerifyMobile from './VerifyMobile';
import OtpSale from './OtpSale';
import Success from '../Success'
import responseHandleSrv from '../.././../services/responseHandleSrv';
import axios from '../../../axios';
import Api from '../../../constants/Api';
import { authBasicHeader } from '../../../services/auth-header';

export default function StepsForm() {
    const [current, setCurrent] = useState(-1);
    const [profile, setProfile] = useState({});
    const [lineProfile, setLineProfile] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('กำลังตรวจสอบ Config...');
    const [config, setConfig] = useState({});
    const root = window.location.origin;
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    useEffect(() => {
        setIsLoading(true);
        const liff = window.liff;
        getConfig().then(res => {
            if (res.data.success) {
                if (res.data.data) {
                    setConfig(res.data.data);
                    getData(liff, res.data.data.LiffId);
                } else {
                    responseHandleSrv.handleErrorMsg('แจ้งเตือน', 'ไม่พบข้อมูล');
                }
            }
        })
            .catch(err => {
                setIsLoading(false);
                responseHandleSrv.handleError(err);
            });
    }, []);

    const getData = (liff, liffId) => {
        setLoadingMsg('กำลังตรวจสอบข้อมูล Line...');

        main(liff, liffId).then(profile => {
            if (profile) {
                setLoadingMsg('กำลังตรวจสอบข้อมูลพนักงานขาย...');
                setLineProfile(profile);
                if (typeof (Storage) !== "undefined") {
                    sessionStorage.sale_profile = JSON.stringify(profile);
                }
                getSale(profile.userId).then(response => {
                    if (response.data.success) {
                        if (response.data.data) {
                            setProfile(response.data.data);
                            let res = response.data.data;
                            if (res.IsActivate === 1 && res.IsActive === 1) {
                                setCurrent(3);
                            }
                            if (res.IsActivate === 1 && res.IsActive === 0) {
                                window.location.href = `${root}/frontend/sale/contactAdmin`;
                            }
                        }
                        else {

                            setCurrent(0);
                            setIsLoading(false);
                        }
                    }
                }).catch(err => {
                    setIsLoading(false);
                    responseHandleSrv.handleError(err);
                });
            }
        })
    };
    const getConfig = async () => {
        let name = process.env.REACT_APP_SALE_LIFF_NAME_ACTIVATE;
        return await axios.get(`${Api.UTILITY_FIND_LIFF + name}`, {
            headers: authBasicHeader()
        })
    };

    const main = async (liff, liffId) => {
        await liff.init({ liffId: `${liffId}` }).catch(err => { throw err });
        if (liff.isLoggedIn()) {
            return getUserProfile(liff);
        }
        else {
            return liff.login();
        }
    }

    const getUserProfile = async (liff) => {
        return await liff.getProfile();
    }

    const getSale = async (uid) => {
        return await axios.get(`${Api.SALE_SEARCH_UID + uid}`, {
            headers: authBasicHeader()
        })
    };
    const steps = [
        {
            title: 'First',
            content: 'first content'
        },
        {
            title: 'Second',
            content: 'Second-content',
        },
        {
            title: 'third',
            content: 'Third-content',
        },
        {
            title: 'Last',
            content: 'Last-content',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const setSaleProfile = (profile) => {
        setProfile(profile)
    }
    const setMobileNo = (mobileNo) => {
        let sale = { ...profile };
        sale.MobileNo = mobileNo
        setProfile(sale);
    }

    switch (current) {
        case 0:
            return (
                <>
                    <SearchSale
                        onNextPage={next}
                        onPrevPage={prev}
                        setProfileProp={setSaleProfile}
                        lineProfile={lineProfile}
                    />
                </>
            );
        case 1:
            return (
                <>
                    <VerifyMobile
                        onNextPage={next}
                        onPrevPage={prev}
                        profileValue={profile}
                        lineProfile={lineProfile}
                        setMobileNoProp={setMobileNo}
                    />
                </>
            );
        case 2:
            return (
                <>
                    <OtpSale
                        onNextPage={next}
                        onPrevPage={prev}
                        profileValue={profile}
                        lineProfile={lineProfile}
                    />
                </>
            );
        case 3:
            return (
                <>
                    <Success
                        onNextPage={next}
                    />
                </>
            );
        default:
            return (
                <>
                    <div className='loadingOverlay'>
                        <div className='df-center'>
                            <div className="spinLoader" />
                        </div><br />
                        <div className='df-center'>{loadingMsg}</div>
                    </div>
                </>
            );

    }
};
