import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import responseHandleSrv from '../.././../services/responseHandleSrv';
import axios from '../../../axios';
import Api from '../../../constants/Api';
import { authBasicHeader } from '../../../services/auth-header';

const liff = window.liff;
const root = window.location.origin;
export default function Profile() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('กำลังตรวจสอบ Config...');
    const [config, setConfig] = useState({});

    useEffect(() => {
        setIsLoading(true);

        getConfig().then(res => {
            if (res.data.success) {
                if (res.data.data) {
                    setConfig(res.data.data);
                    getData(liff, res.data.data.LiffId);
                } else {
                    responseHandleSrv.handleErrorMsg('แจ้งเตือน', 'ไม่พบข้อมูล');
                }
            }
        })
            .catch(err => {
                setIsLoading(false);
                responseHandleSrv.handleError(err);
            });


    }, []);
    const getData = (liff, liffId) => {
        setLoadingMsg('กำลังตรวจสอบข้อมูล Line...');
        main(liff, liffId).then(profile => {
            if (profile) {
                setLoadingMsg('กำลังตรวจสอบข้อมูลพนักงานขาย...');
                if (typeof (Storage) !== "undefined") {
                    sessionStorage.sale_profile = JSON.stringify(profile);
                }
                getSale(profile.userId).then(response => {

                    if (response.data.success) {
                        if (response.data.data) {
                            setData(response.data.data);
                            let res = response.data.data;
                            setIsLoading(false);
                        }
                        else {
                            window.location.href = `${root}/frontend/sale/contactAdmin`;
                        }

                    }
                }).catch(err => {
                    setIsLoading(false);
                    responseHandleSrv.handleError(err);
                });
            }
        })
    }
    const getConfig = async () => {
        let name = process.env.REACT_APP_SALE_LIFF_NAME_PROFILE;
        return await axios.get(`${Api.UTILITY_FIND_LIFF + name}`, {
            headers: authBasicHeader()
        })
    };
    const main = async (liff, liffId) => {
        await liff.init({ liffId: `${liffId}` }).catch(err => { throw err });
        if (liff.isLoggedIn()) {
            return getUserProfile(liff);
        }
        else {
            return liff.login();
        }
    }

    const getUserProfile = async (liff) => {
        return await liff.getProfile();
    }

    const getSale = async (uid) => {
        return await axios.get(`${Api.SALE_SEARCH_UID + uid}`, {
            headers: authBasicHeader()
        })
    };
    const closeLiff = () => {
        liff.closeWindow();
    };
    return (
        <>
            {
                isLoading === true ?
                    <div className='loadingOverlay'>
                        <div className='df-center'><div className="spinLoader" /></div><br />
                        <div className='df-center'>{loadingMsg}</div>
                    </div> :
                    <>
                        {Object.entries(data).length > 0 &&
                            <div style={{ padding: '5%' }}>
                                <div className='df-center'>ข้อมูลส่วนตัว</div><br />
                                <div>รหัส:&nbsp;<b className='primaryColor'>{data.Code}</b></div>
                                <div>ชื่อ:&nbsp;<b className='primaryColor'>{data.FullName}</b></div>
                                <div>ชื่อบริษัท:&nbsp;<b className='primaryColor'>{data.DistributorName ? data.DistributorName : "-"}</b></div>
                                <div>กลุ่มการขาย:&nbsp;<b className='primaryColor'>{data.ShortName ? data.ShortName : '-'}</b></div>
                                <div>เบอร์มือถือ:&nbsp;<b className='primaryColor'>{data.MobileNo ? data.MobileNo : '-'}</b></div>
                                <br />
                                <div className='df-center'>
                                    <Button type='primary' shape="round" style={{ width: '30%' }} onClick={closeLiff} >ปิด</Button>
                                </div>
                                <br />
                                <div className='df-center' style={{ color: 'red' }}>*หากต้องการกลับสู่เมนูหลัก กดปุ่ม X เพื่อปิด</div>
                            </div>
                        }
                    </>
            }

        </>
    )
}
