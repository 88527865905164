import React, { useState } from 'react'
import axios from '../../../axios';
import { Form, Input, Button, notification } from 'antd'
import { Grid } from '@mui/material'
import { AiOutlineUser, AiOutlineLock } from "react-icons/ai";
import auth from '../../../services/auth';
import Api from '../../../constants/Api';
import responseHandleSrv from '../../../services/responseHandleSrv';
import LoginIllust from '../../../styles/images/LoginIllust.svg'
export default function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const root = window.location.origin;

    const onFinish = (values) => {
        setIsLoading(true);
        if (values.username && values.password) {
            (async () => {
                await axios.post(`${Api.AUTH_LOGIN}`, {
                    username: values.username,
                    password: values.password
                }).then((response) => {
                    if (response.data.success === true) {

                        if (typeof (Storage) !== "undefined") {
                            localStorage.user = JSON.stringify(response.data.data);
                        }
                        window.location.href = `${root}/backend/sale`;
                    }
                }).catch(error => {
                    setIsLoading(false);
                    responseHandleSrv.handleError(error);

                });
            })();
        }
    };
    const onFinishFailed = (values) => {

        const [{ errors }] = values.errorFields
        notification.error({
            message: 'กรอกข้อมูลไม่ครบ',
            description: errors,
        });

    };
    return (
        <div>
            {
                isLoading === true ?
                    <div className='loadingOverlay'>
                        <div className='df-center'><div className="spinLoader" /></div>
                    </div> :
                    <div className='df-centerXY loginPage' >
                        <Grid container>
                            <Grid item xs={12} sm={12} md={6} className='df-centerXY pdPage' >
                                <img src={LoginIllust} className='loginIllust' />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className='loginContent' >
                                <div className='loginHeader'>Login</div>
                                <Form
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                >
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Form.Item
                                                name='username'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'กรอก Username!',
                                                    }
                                                ]}
                                            >
                                                <Input className='loginForm' prefix={<AiOutlineUser />} size='large' placeholder='Username' />
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Form.Item name='password'
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'กรอก Password',
                                                    }
                                                ]}
                                            >
                                                <Input.Password className='loginForm' prefix={<AiOutlineLock />} size='large' placeholder='Password' />
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Form.Item>
                                                <a href={`${root}/changePass`}>forgot your password?</a>
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} className='df-center'>
                                            <Button type='primary' size='large' shape='round' style={{ width: '30%' }} htmlType="submit" className='primaryRoundBtnAnimate'>Login</Button>
                                        </Grid>
                                    </Grid>
                                </Form>

                            </Grid>
                        </Grid>

                    </div>
            }
        </div>
    )
}
