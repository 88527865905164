import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes as Switch, Route, useLocation } from "react-router-dom";
import './App.css';
import './styles/antdFix.css';
import './styles/organize.css';
import './styles/style.css';
import moment from 'moment';


import auth from './services/auth';
import Sale from './components/Backend/Sale';
import Company from './components/Backend/Company';
import Distibutor from './components/Backend/Distributor';
import SaleType from './components/Backend/SaleType';
import User from './components/Backend/User';
import Role from './components/Backend/Role';
import SaleProfile from './components/Frontend/Sale/Profile';

import OtpSale from './components/Frontend/Sale/OtpSale';

import StepsForm from './components/Frontend/Sale/StepsForm';
import SearchSale from './components/Frontend/Sale/SearchSale';

import ContactAdmin from './components/Frontend/ContactAdmin'
import Success from './components/Frontend/Success';
import MsgAdmin from './components/Frontend/MsgAdmin'

import Login from './components/Backend/auth/Login'
import ChangePassword from './components/Backend/auth/ChangePassword';
import HeaderApp from './header/HeaderApp';

export default function App() {
  const location = useLocation()
  // console.log('location', location);
  let currUser = auth.getCurrentUser();
  // console.log('currUser', currUser);

  useEffect(() => {
    console.log(location.pathname.includes('backend'));
    if (location.pathname.includes('backend')) {
      if (currUser != null) {
        let cDate = moment();
        let eDate = currUser.expireDate;
        // console.log('eDate', eDate);
        // let eDate = "Mon, 10 July 2023 10:46:17 GMT";
        eDate = moment(eDate).zone("-0000").format("YYYY-MM-DD HH:mm:ss");

        let diffDate = cDate.diff(eDate);

        console.log('diffDate', diffDate);

        let diffTxt = moment.duration(diffDate).as("second");

        if (diffDate > 0) {
          auth.logout();
        }
      } else {
        auth.logout();
      }
    }
  }, [location]);
  return (
    // <Router basename="/">
    <Switch>
      <Route path='/' element={<Login />} />
      <Route path='/changePass' element={<ChangePassword />} />
      <Route path='/headerApp' element={<HeaderApp />} />

      {/* backend */}
      <Route path='/backend/sale' element={currUser === null ? <Login /> : <Sale />} />
      <Route path='/backend/company' element={currUser === null ? <Login /> : <Company />} />
      <Route path='/backend/distributor' element={currUser === null ? <Login /> : <Distibutor />} />
      <Route path='/backend/saleType' element={currUser === null ? <Login /> : <SaleType />} />
      <Route path='/backend/role' element={currUser === null ? <Login /> : <Role />} />
      <Route path='/backend/user' element={currUser === null ? <Login /> : <User />} />

      {/* Frontend */}

      <Route path='/frontend/sale/search' element={<SearchSale />} />

      <Route path='/frontend/sale/steps' element={<StepsForm />} />
      <Route path='/frontend/sale/profile' element={<SaleProfile />} />
      <Route path='/frontend/sale/otp' element={<OtpSale />} />

      <Route path='/frontend/sale/contactAdmin' element={<ContactAdmin />} />
      <Route path='/frontend/sale/success' element={<Success />} />
      <Route path='/frontend/msg-admin' element={<MsgAdmin />} />

    </Switch>
    // </Router>
  )
}

