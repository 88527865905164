import React from 'react';
import { Result } from 'antd';

export default function ContactAdmin() {
    return (
        <div className='dfc-centerXYpage'>
            <Result
                status="warning"
                title={<b style={{color: '#faad14'}} className='df-centerWarp'><div>ไม่สามารถใช้งานได้</div>&nbsp;<div>กรุณาติดต่อแอดมิน</div></b>}
                subTitle={<span style={{color: '#ff4d4f'}}>*หากต้องการออกจากหน้าจอ กดปุ่ม X เพื่อปิด</span>}
            />
        </div>
    )
}
