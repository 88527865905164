import React, { useEffect, useState } from 'react'
import HeaderApp from '../../header/HeaderApp'
import MenuApp from '../../constants/MenuApp'
import { Breadcrumb, Checkbox, Form, Input, Select, Button, Space, Table, Tag, Modal, Switch, Pagination, notification } from 'antd'
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import { RiEditCircleFill, RiAddCircleFill } from "react-icons/ri";
import { FaUserTimes } from "react-icons/fa";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Grid, LinearProgress } from '@mui/material'
import responseHandleSrv from '../../services/responseHandleSrv';
import axios from '../../axios';
import { authHeader } from '../../services/auth-header';
import Api from '../../constants/Api';
import { tagLabelActive } from '../../services/function';;

export default function Sale() {
    const { Option } = Select;
    const { confirm } = Modal;
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [data, setData] = useState([]);
    const [dataEdit, setDataEdit] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [distributorList, setDistributorList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [saleTypeList, setSaleTypeList] = useState([]);
    const [checkActivate, setCheckActive] = React.useState(false);
    const [valueID, setValueID] = React.useState('');
    const [token, setToken] = React.useState('');
    const [isOverlay, setOverlay] = useState(false);
    const [activateList, setActivateList] = useState([
        { Id: 1, Name: "ยืนยันตัวตนแล้ว" }, { Id: 0, Name: "ยังไม่ได้ยืนยันตัวตน" }]);
    const [pageData, setPageData] = useState({
        currentPage: 1,
        pageSize: 10,
        totalPage: '',
        previousPage: '',
        nextPage: '',
        totalcount: '',
        goToPage: ''
    });
    const [filter, setFilter] = useState({
        search: '',
        CompanyId: '',
        DistributorId: '',
        SaleTypeId: '',
        IsActivate: ''
    });
    const [form] = Form.useForm();
    const [formadd] = Form.useForm();
    const [formedit] = Form.useForm();

    let user = localStorage.length === 0 ? null : JSON.parse(localStorage.user);
    useEffect(() => {
        //  formadd.resetFields();
        // formedit.resetFields();
        onClear();
        getCompanyList();
        getDistributorList();
        getSaleTypeList();
    }, []);
    const getCompanyList = () => {
        (async () => {
            await axios.get(`${Api.COMPANY_DROPDOWN}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    setCompanyList(response.data.data);
                }

            })
        })();
    };
    const getDistributorList = () => {
        (async () => {
            await axios.get(`${Api.DISTRIBUTOR_DROPDOWN}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    setDistributorList(response.data.data);
                }

            })
        })();
    };
    const getSaleTypeList = () => {
        (async () => {
            await axios.get(`${Api.SALETYPE_DROPDOWN}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    setSaleTypeList(response.data.data);
                }

            })
        })();
    };
    const showModdalCreate = () => {
        setVisibleCreate(true);
        formadd.resetFields();
    }
    const showModalEdit = (record) => {
        console.log('showModalEdit', record);
        setVisibleEdit(true);
        setDataEdit(record);

        setValueID(record.Id)
        if (record.IsActivate == 1) {
            setCheckActive(true);
        }

        let Distributor = record.DistributorId ? (distributorList.find(dist => dist.Id == record.DistributorId) ? record.DistributorId : null) : null;
        let Company = record.CompanyId ? (companyList.find(comp => comp.Id == record.CompanyId) ? record.CompanyId : null) : null;
        let SaleType = record.SaleTypeId ? (saleTypeList.find(type => type.Id == record.SaleTypeId) ? record.SaleTypeId : null) : null;

        formedit.resetFields();
        formedit.setFieldsValue({
            'FirstName': record.FirstName ? record.FirstName : '',
            'LastName': record.LastName ? record.LastName : '',
            'Code': record.Code ? record.Code : '',
            'MobileNo': record.MobileNo ? record.MobileNo : '',
            'DistributorId': Distributor ? record.DistributorId : null,
            'SaleGroup': Distributor ? record.ShortName : null,
            'CompanyId': Company ? record.CompanyId : null,
            'SaleTypeId': SaleType ? record.SaleTypeId : null,
            'IsActive': record.IsActive === 1 ? true : false,
            'IsActivate': record.IsActivate === 1 ? true : false,
            'LineId': record.LineId ? record.LineId : '',

        });
    };

    const showModalDeActivate = (values) => {

        confirm({
            title: 'คุณแน่ใจที่จะยกเลิกยืนยันตัวตนรายการนี้?',
            okText: 'ใช่',
            cancelText: 'ไม่',
            okType: 'danger',
            okButtonProps: { className: 'dangerOutlAnimate' },
            cancelButtonProps: { className: 'radiusBtn' },
            centered: true,
            closable: true,
            onOk() {
                deActivate(values);
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const deActivate = (values) => {

        // values.MobileNo = '';
        // values.IsActive = values.IsActive === 1 ? true : false;
        // console.log('values',values);
        (async () => {
            await axios.put(`${Api.SALE_DEACTIVATE + values.Id}`, {}, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    // getSapToken(values,'deActivate');
                    onClear();
                    unlinkRichMenu(values.LineId);

                }

            })
        })();
    }

    const unlinkRichMenu = (lineId) => {
        let data = {
            LineId: lineId
        };
        (async () => {
            await axios.post(`${Api.UTILITY_SALE_UNLINK_RICHMENU}`, data, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {

                }

            })
        })();
    }

    const addUserToRichMenu = (SaleTypeId, lineId) => {
        let data = {
            SaleTypeId,
            LineId: lineId
        };
        (async () => {
            await axios.post(`${Api.UTILITY_SALE_ADD_USER_RICHMENU}`, data, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {

                }

            })
        })();
    }

    const handleOk = () => {
        setVisibleCreate(false);
    }
    const handleCancel = () => {
        setVisibleCreate(false);
        formadd.resetFields();
        onClear();
    }
    const handleCancelEdit = () => {
        setVisibleEdit(false);
        setValueID('');
        setCheckActive(false);

        formedit.resetFields();
        onClear();
    }
    const onChangePage = (page, pageSize) => {

        let pp = {
            ...pageData,
            "currentPage": page,
            "pageSize": pageSize
        }
        setPageData(pp);

        getData(page, pageSize, filter.search, filter.CompanyId, filter.DistributorId, filter.SaleTypeId, filter.IsActivate);

    }
    const getData = (page, pageSize, search, CompanyId, DistributorId, SaleTypeId, IsActivate) => {
        (async () => {
            setIsLoading(true)
            await axios.get(`${Api.SALE_SEARCH_LIST}`, {
                headers: authHeader(),
                params: {
                    search,
                    CompanyId,
                    DistributorId,
                    SaleTypeId,
                    IsActivate,
                    page,
                    skip: pageSize,
                }
            }).then(response => {
                if (response.data.success) {
                    let data = response.data.data.result;
                    setData(data);

                    let dd = {
                        ...pageData,
                        "currentPage": page,
                        "pageSize": pageSize,
                        "totalPage": response.data.totalPage,
                        "previousPage": response.data.previousPage,
                        "nextPage": response.data.nextPage,
                        "totalcount": response.data.data.totalCount,
                        "goToPage": ''
                    }

                    setPageData(dd);
                }
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    };
    const onClear = () => {

        form.resetFields();

        getData(1, pageData.pageSize, '', '', '', '', '');

        let dd = {
            ...pageData,
            "currentPage": 1,
            "pageSize": pageData.pageSize,
            "totalPage": '',
            "previousPage": '',
            "nextPage": '',
            "totalcount": ''
        }
        setPageData(dd);

        let ft = {
            ...filter,
            "search": '',
            'CompanyId': '',
            'DistributorId': '',
            'SaleTypeId': '',
            'IsActivate': ''
        }
        setFilter(ft);
    }
    const onFinish = (values) => {

        let search = values.search !== undefined ? values.search : '';
        // let startDate = '';
        // let endDate = '';
        let CompanyId = values.CompanyId !== undefined ? values.CompanyId : '';
        let DistributorId = values.DistributorId !== undefined ? values.DistributorId : '';
        let SaleTypeId = values.SaleTypeId !== undefined ? values.SaleTypeId : '';
        let IsActivate = values.IsActivate !== undefined ? values.IsActivate : '';

        // let rangPicker = values.range;
        // if (rangPicker) {
        //   startDate = rangPicker[0].utcOffset(0, true).format();
        //   endDate = rangPicker[1].utcOffset(0, true).format();
        // }

        let ft = {
            ...filter,
            "search": search,
            "CompanyId": CompanyId,
            "DistributorId": DistributorId,
            "SaleTypeId": SaleTypeId,
            "IsActivate": IsActivate
        }

        setFilter(ft);

        getData(1, pageData.pageSize, search, CompanyId, DistributorId, SaleTypeId, IsActivate);

    };

    const columns = [
        {
            title: 'รหัส',
            dataIndex: 'Code',
            key: 'Code',
            align: 'center',
            width: 160
        },
        {
            title: 'ชื่อ',
            dataIndex: 'FullName',
            key: 'FullName',
            align: 'center'
        },
        {
            title: 'Distributor',
            dataIndex: 'DistributorName',
            key: 'DistributorName',
            align: 'center',
            width: 170,
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'บริษัท',
            key: 'CompanyName',
            dataIndex: 'CompanyName',
            align: 'center',
            width: 170,
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'เบอร์โทรศัพท์',
            key: 'MobileNo',
            dataIndex: 'MobileNo',
            align: 'center',
            width: 150,
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'Sale Type',
            key: 'SaleTypeName',
            dataIndex: 'SaleTypeName',
            align: 'center',
            width: 150,
        },
        {
            title: 'สถานะการใช้งาน',
            key: 'IsActive',
            dataIndex: 'IsActive',
            align: 'center',
            width: 140,
            render: (text, record) => (
                <>
                    {
                        text === 0 ? <div><Tag color={tagLabelActive[record.IsActive]}>ปิดการใช้งาน</Tag></div>
                            : <div><Tag color={tagLabelActive[record.IsActive]}>เปิดการใช้งาน</Tag></div>
                    }

                </>
            )
        },
        {
            title: 'สถานะการยืนยันตัวตน',
            key: 'IsActivate',
            dataIndex: 'IsActivate',
            align: 'center',
            width: 170,
            render: (text, record) => (
                <>
                    {
                        text === 0 ? <div><Tag color={tagLabelActive[record.IsActivate]}>No</Tag></div>
                            : <div><Tag color={tagLabelActive[record.IsActivate]}>Yes</Tag></div>
                    }

                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: 110,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="text" shape="circle" icon={<AiOutlineEdit />} onClick={() => showModalEdit(record)} />
                    {
                        (record.IsActivate === 1 && record.IsActive === 1) ? <Button type="text" shape="circle" danger icon={<FaUserTimes />} onClick={() => showModalDeActivate(record)} />
                            : ''
                    }

                </Space>
            ),
        },
    ];

    const onFinishAdd = (values) => {

        let params = {
            CompanyId: values.CompanyId !== undefined ? values.CompanyId : null,
            Code: values.Code !== undefined ? values.Code : '',
            FirstName: values.FirstName !== undefined ? values.FirstName : '',
            LastName: values.LastName !== undefined ? values.LastName : '',
            MobileNo: values.MobileNo !== undefined ? values.MobileNo : '',
            DistributorId: values.DistributorId !== undefined ? values.DistributorId : null,
            SaleTypeId: values.SaleTypeId !== undefined ? values.SaleTypeId : null,
            IsActive: values.IsActive !== undefined ? values.IsActive : false
        };

        (async () => {
            setIsLoading(true);
            setOverlay(true);
            await axios.post(`${Api.SALE_CREATE}`, params, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    responseHandleSrv.handleSuccess(response);
                    handleCancel();
                    setIsLoading(false);
                    setOverlay(false);

                    //create sap
                    // getSapToken(params, 'add');
                }
            }).catch(err => {
                setOverlay(false);
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    };

    const onFinishEdit = (values) => {
        console.log('onFinishEdit', values);
        let params = {
            CompanyId: values.CompanyId !== undefined ? values.CompanyId : null,
            Code: values.Code !== undefined ? values.Code : '',
            FirstName: values.FirstName !== undefined ? values.FirstName : '',
            LastName: values.LastName !== undefined ? values.LastName : '',
            MobileNo: values.MobileNo !== undefined ? values.MobileNo : '',
            DistributorId: values.DistributorId !== undefined ? values.DistributorId : null,
            SaleTypeId: values.SaleTypeId !== undefined ? values.SaleTypeId : null,
            IsActive: values.IsActive !== undefined ? values.IsActive : false
        };

        (async () => {
            setIsLoading(true);
            setOverlay(true);
            await axios.put(`${Api.SALE_UPDATE + valueID}`, params, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    if (params.IsActive === true && values.IsActivate === true) {
                        
                        //update sap
                        getSapToken(params, 'edit');

                    } else {
                        responseHandleSrv.handleSuccess(response);
                        handleCancelEdit();
                        setIsLoading(false);
                        setOverlay(false);
                    }
                    checkChangeRichMenu(values);

                }
            }).catch(err => {
                setOverlay(false);
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    };

    const saleEmployeeSap = (body, from) => {

        (async () => {
            await axios.post(`${Api.ACT_SALE_EMPLOYEE}`, body, {
                headers: authHeader()
            }).then(response => {
                console.log('saleEmployeeSap', response);
                if (response.data.success) {
                    let res = response.data.data;
                    if (res.status_code === "200" && res.message_id === "1501") {
                        responseHandleSrv.handleSuccess(response);

                        if (from === 'add') {
                            handleCancel();
                        }
                        if (from === 'edit') {
                            handleCancelEdit();
                        }
                    } else {
                        responseHandleSrv.handleErrorMsg('เกิดข้อผิดพลาด', res.message);
                    }
                    setOverlay(false);
                    setIsLoading(false);
                }
            }).catch(err => {
                // console.log(err);
                setOverlay(false);
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    };

    const getSapToken = (body, from) => {
        (async () => {
            await axios.get(`${Api.ACT_TOKEN}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    console.log('getSapToken', response);
                    let res = response.data.data;
                    body.token = res.token;
                    saleEmployeeSap(body, from)
                    setToken(res.token);
                }
            }).catch(err => {
                // console.log(err);
                setOverlay(false);
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    };

    const checkChangeRichMenu = (values) => {
        if (values.IsActive === false) {
            //ปิดการใช้งาน
            if (dataEdit.IsActive === 1 && dataEdit.IsActivate === 1) {
                // alert("InActive&Unlink")
                unlinkRichMenu(dataEdit.LineId);
            }
        }
        else {
            //เปิดการใช้งาน
            if (values.SaleTypeId !== dataEdit.SaleTypeId) {
                if (dataEdit.IsActivate === 1) {
                    // alert('change saletype and change rich-menu');
                    addUserToRichMenu(values.SaleTypeId, dataEdit.LineId);
                }
            }
            else { //not change saletype
                if (dataEdit.IsActive === 0 && dataEdit.IsActivate === 1) {
                    // alert('active and not saletype and change rich-menu');
                    addUserToRichMenu(dataEdit.SaleTypeId, dataEdit.LineId);
                }
            }
        }
    }

    const onChangeDist = (value, e) => {

        if (e) {
            let find = distributorList.find(x => x.Id === e);

            if (value === 'add') {
                formadd.setFieldsValue({
                    'SaleGroup': find.ShortName
                });
            }
            else {
                formedit.setFieldsValue({
                    'SaleGroup': find.ShortName
                });
            }
        }
        else { //not value
            if (value === 'add') {
                formadd.resetFields([
                    'SaleGroup',
                ]);
            }
            else {
                formedit.resetFields([
                    'SaleGroup',
                ]);
            }
        }
    };
    return (
        <>
            <div id="overlay" style={{ display: isOverlay === true ? "block" : "none" }}><div class="text-overlay-center">Loading...</div></div>
            <div className="page-container">
                <HeaderApp />
                <div className='pageSider'>
                    <MenuApp />
                    <div className="main-content">
                        <Modal
                            title={<div className='modalHeader'><RiEditCircleFill size='20px' />&nbsp;&nbsp;Add sale</div>}
                            open={visibleCreate}
                            footer={false}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            centered='true'
                            className='modalCreate'
                            destroyOnClose={true}
                        >
                            <Form
                                layout="vertical"
                                form={formadd}
                                name="add-sale"
                                onFinish={onFinishAdd}
                                onFinishFailed={responseHandleSrv.onFinishFailed}
                                initialValues={{
                                    'IsActive': true
                                }}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ชื่อ'
                                            name='FirstName'
                                            rules={[{ required: true, message: 'กรอกชื่อ' }]}
                                        >
                                            <Input placeholder='กรอกชื่อ'></Input>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='นามสกุล'
                                            name='LastName'
                                            rules={[{ required: true, message: 'กรอกนามสกุล' }]}
                                        >
                                            <Input placeholder='กรอกนามสกุล'></Input>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='รหัส Sale'
                                            name='Code'
                                            rules={[{ required: true, message: 'กรอกรหัส Sale' }]}
                                        >
                                            <Input placeholder='กรอกรหัส Sale'></Input>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='เบอร์โทรศัพท์'
                                            name='MobileNo'
                                            rules={[
                                                {
                                                    pattern: /^[0][0-9]{9}$\b/,
                                                    message: 'เบอร์โทรศัพท์มือถือควรเป็นตัวเลข ขึ้นต้นด้วย 0 และมี 10 หลัก'
                                                }

                                            ]}
                                        >
                                            <Input placeholder='กรอกเบอร์โทรศัพท์' maxLength="10" minLength="10"></Input>
                                        </Form.Item>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Distributor'
                                            name='DistributorId'
                                            rules={[{ required: true, message: 'เลือก Distributor' }]}
                                        >
                                            <Select
                                                placeholder='เลือก Distributor'
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                                // onClear={onClearDistributor.bind(this, 'add')}
                                                onChange={onChangeDist.bind(this, 'add')}
                                            >
                                                {distributorList && distributorList.map((s) => <Option value={s.Id} key={s.Id}>{s.Name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Sale Group'
                                            name='SaleGroup'
                                        >
                                            <Input placeholder='Sale Group' disabled></Input>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='บริษัท'
                                            name='CompanyId'
                                            rules={[{ required: true, message: 'เลือกบริษัท' }]}
                                        >
                                            <Select
                                                placeholder='เลือกบริษัท'
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                            >
                                                {companyList && companyList.map((s) => <Option value={s.Id} key={s.Id}>{s.Name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Sale Type'
                                            name='SaleTypeId'
                                            rules={[{ required: true, message: 'เลือก Sale Type' }]}
                                        >
                                            <Select
                                                placeholder='เลือก Sale Type'
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"

                                            >
                                                {saleTypeList && saleTypeList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='สถานะการใช้งาน'
                                            name='IsActive'
                                            valuePropName='checked'
                                        >
                                            <Switch defaultChecked checkedChildren='เปิด' unCheckedChildren='ปิด'></Switch>
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                                <div className='df-flexEnd'>
                                    <Button onClick={() => handleCancel()} className='radiusBtn'>Cancel</Button>&nbsp;
                                    <Button type='primary' htmlType='submit' className='primaryBtnAnimate'>Save</Button>
                                </div>
                            </Form>
                        </Modal>

                        {/* modal edit */}
                        <Modal
                            title={<div className='modalHeader'><RiEditCircleFill size='20px' />&nbsp;&nbsp;Edit Sale</div>}
                            open={visibleEdit}
                            footer={false}
                            onOk={handleOk}
                            onCancel={handleCancelEdit}
                            centered='true'
                            className='modalCreate'
                            destroyOnClose={true}
                        >
                            <Form
                                layout="vertical"
                                form={formedit}
                                name="edit-sale"
                                onFinish={onFinishEdit}
                                onFinishFailed={responseHandleSrv.onFinishFailed}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ชื่อ'
                                            name='FirstName'
                                            rules={[{ required: true, message: 'กรอกชื่อ' }]}
                                        >
                                            <Input placeholder='กรอกชื่อ'></Input>
                                        </Form.Item>

                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='นามสกุล'
                                            name='LastName'
                                            rules={[{ required: true, message: 'กรอกนามสกุล' }]}
                                        >
                                            <Input placeholder='กรอกนามสกุล'></Input>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='รหัส Sale'
                                            name='Code'
                                            rules={[{ required: true, message: 'กรอกรหัส Sale' }]}
                                        >
                                            <Input placeholder='กรอกรหัส Sale'></Input>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='เบอร์โทรศัพท์'
                                            name='MobileNo'
                                            rules={[
                                                {
                                                    pattern: /^[0][0-9]{9}$\b/,
                                                    message: 'เบอร์โทรศัพท์มือถือควรเป็นตัวเลข ขึ้นต้นด้วย 0 และมี 10 หลัก'
                                                }

                                            ]}
                                        >
                                            <Input placeholder='กรอกเบอร์โทรศัพท์' disabled={checkActivate} maxLength="10" minLength="10"></Input>
                                        </Form.Item>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Distributor'
                                            name='DistributorId'
                                            rules={[{ required: true, message: 'เลือก Distributor' }]}
                                        >
                                            <Select
                                                placeholder='เลือก Distributor'
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                                onChange={onChangeDist.bind(this, 'edit')}
                                            >
                                                {distributorList && distributorList.map((s) => <Option value={s.Id} key={s.Id}>{s.Name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Sale Group'
                                            name='SaleGroup'
                                        >
                                            <Input disabled></Input>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='บริษัท'
                                            name='CompanyId'
                                            rules={[{ required: true, message: 'เลือกบริษัท' }]}
                                        >
                                            <Select
                                                placeholder='เลือกบริษัท'
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                            >
                                                {companyList && companyList.map((s) => <Option value={s.Id} key={s.Id}>{s.Name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Sale Type'
                                            name='SaleTypeId'
                                            rules={[{ required: true, message: 'เลือก Sale Type' }]}
                                        >
                                            <Select
                                                placeholder='เลือ Sale Type'
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"

                                            >
                                                {saleTypeList && saleTypeList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Line Id'
                                            name='LineId'
                                        >
                                            <Input disabled></Input>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='สถานะการใช้งาน'
                                            name='IsActive'
                                            valuePropName='checked'
                                        >
                                            <Switch defaultChecked checkedChildren='เปิด' unCheckedChildren='ปิด'></Switch>
                                        </Form.Item>
                                    </Grid>
                                    <Form.Item
                                        name='IsActivate'
                                    >
                                        {/* <Input  hidden={true}></Input> */}
                                    </Form.Item>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentValues) => currentValues.IsActivate === true}
                                        >
                                            {({ getFieldValue }) => getFieldValue('IsActivate') === true ?
                                                (
                                                    <Form.Item
                                                        label='สถานะการยืนยันตัวตน'
                                                        name='Other'

                                                    >
                                                        <Tag icon={<CheckCircleOutlined />} color="success">
                                                            ยืนยันตัวตนแล้ว</Tag>
                                                    </Form.Item>
                                                )
                                                :
                                                (

                                                    <Form.Item
                                                        label='สถานะการยืนยันตัวตน'
                                                        name='Other'
                                                    >
                                                        <Tag icon={<CloseCircleOutlined />} color="default">
                                                            ยังไม่ได้ยืนยันตัวตน
                                                        </Tag>
                                                    </Form.Item>
                                                )
                                            }

                                        </Form.Item>
                                    </Grid>
                                </Grid>
                                <div className='df-flexEnd'>
                                    <Button onClick={() => handleCancelEdit()} className='radiusBtn'>Cancel</Button>&nbsp;
                                    <Button type='primary' htmlType='submit' className='primaryBtnAnimate'>Save</Button>
                                </div>
                            </Form>
                        </Modal>


                        <div className='pdPage'>
                            <div className='buttonHeader'>
                                <div>
                                    <b className='pageTitle'>Sale</b>
                                    <Breadcrumb className='breadcrumbStyle' items={[{ title: 'Sale', href: '/backend/sale' }]} />
                                </div>
                                <Button type='primary' onClick={() => showModdalCreate()} className='df-centerY primaryBtnAnimate'><AiOutlinePlus />&nbsp;&nbsp;Add Sale</Button>
                            </div>

                            <div className='searchCard'>
                                <Form
                                    layout='vertical'
                                    form={form}
                                    name="advanced_search"
                                    onFinish={onFinish}
                                >
                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item label='ค้นหา' name="search">
                                                <Input placeholder='รหัส Sale, ชื่อ, นามสกุล, เบอร์โทรศัพท์'></Input>
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item label='บริษัท' name="CompanyId">
                                                <Select
                                                    placeholder='บริษัท'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {companyList && companyList.map((s) => <Option value={s.Id} key={s.Id}>{s.Name}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item label='Distributor' name="DistributorId">
                                                <Select
                                                    placeholder='Distributor'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {distributorList && distributorList.map((s) => <Option value={s.Id} key={s.Id}>{s.Name}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item label='Sale Type' name="SaleTypeId">
                                                <Select
                                                    placeholder='Sale Type'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {saleTypeList && saleTypeList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <Form.Item label='การยืนยันตัวตน' name="IsActivate">
                                                <Select
                                                    placeholder='การยืนยันตัวตน'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {activateList && activateList.map((s) => <Option value={s.Id} key={s.Id}>{s.Name}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} className='df-centerY'>Result: {pageData.totalcount} {pageData.totalcount > 0 ? (pageData.totalcount === 1 ? "item" : "items") : ""}</Grid>
                                        <Grid item xs={12} sm={6} className='searchBtnOrg'>
                                            <Button type='primary' htmlType='submit' className='df-centerY primaryBtnAnimate'><AiOutlineSearch />&nbsp;&nbsp;ค้นหา</Button>&nbsp;
                                            <Button danger className='df-centerY dangerOutlAnimate' onClick={() => onClear()}><AiOutlineDelete />&nbsp;&nbsp;เคลียร์</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </div>
                            <div>
                                {isLoading === true ?
                                    <div className="linearloader" /> :
                                    <>
                                        <Table
                                            className='headerTable-color listData-card'
                                            columns={columns}
                                            dataSource={data}
                                            pagination={false}
                                            scroll={{ x: 1700 }}
                                            rowKey={record => record.Id}
                                            rowClassName={'tableBody'}
                                        />
                                        <Pagination
                                            total={pageData.totalcount}
                                            showSizeChanger
                                            showQuickJumper
                                            current={pageData.currentPage}
                                            defaultPageSize={pageData.pageSize}
                                            onChange={onChangePage}
                                            responsive={true}
                                            className="tableBottom"
                                        />
                                    </>
                                }
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}
