import React, { useState } from 'react'
import { AiOutlineMenuFold, AiOutlineMenuUnfold, AiOutlineUser } from 'react-icons/ai';
import { MdPerson, MdEmail, MdPhone, MdLogout } from "react-icons/md";
import { Button, Layout, Menu, theme, Popover, Avatar, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../services/action';
import auth from '../services/auth';

const HeaderApp = () => {
  const collapsedd = useSelector(state => state.mains.changecollape);

  const dispatch = useDispatch();
  const onchangecollape = () => {
    dispatch(actions.changecollape());
    dispatch(actions.changeToggle());
  }
  const currUser = auth.getCurrentUser();
  const LogOut = () => {

    auth.logout();
  }
  return (
    <div className='headerApp'>
      <Button
        type="text"
        icon={collapsedd ? <AiOutlineMenuUnfold /> : <AiOutlineMenuFold />}
        onClick={onchangecollape}
        style={{ fontSize: '28px', width: 45, height: 45, }}
      />
      <Popover
        trigger="click"
        placement='bottomLeft'
        arrow={false}
        content={
          <div style={{ padding: '12px', fontSize: '16px' }}>
            <div className='df-center'><Avatar size='large' icon={<AiOutlineUser />} /></div>
            <div className='lockoutDetail'>
              <div className='df-centerY'>&nbsp;<MdPerson color='#434343' />&nbsp;&nbsp;{currUser.username}</div>
            </div>
            <Button type='text' className='lockoutBtn df-centerY' shape='round' onClick={LogOut}>&nbsp;<MdLogout />&nbsp;&nbsp;Logout</Button>
          </div>
        }
      >
        <Button className='headerLockout' shape="round" style={{ marginRight: '10px' }}>
          <div className='df-centerY'>
            <Avatar icon={<AiOutlineUser />} />
            <div>&nbsp;&nbsp;{currUser.username}</div>
          </div>
        </Button>
      </Popover>
    </div>
  );
};

export default HeaderApp;
