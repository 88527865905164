import React from 'react';
import { Result, Button } from 'antd';

export default function Success() {
    return (
        <div className='dfc-centerXYpage'>
            <Result
                status="success"
                title={<b style={{color: '#52c41a'}} className='df-centerWarp'>ท่านลงทะเบียนเป็นพนักงานขาย ทันใจดี เรียบร้อยแล้ว</b>}
                subTitle={<span style={{color: '#ff4d4f'}}>*หากต้องการออกจากหน้าจอ กดปุ่ม X เพื่อปิด</span>}
                // extra={[
                //     <Button type="primary">
                //         <div className='df-centerY'><AiOutlineArrowLeft />&nbsp;&nbsp;กลับไปหน้าหลัก</div>
                //     </Button>
                // ]}
            />
        </div>
    )
}
