//ไฟล์ทำหน้าที่สร้างตัว store
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import rootReducer from './reducers'

function configureStore(initialState) {
    const middleware = []
    const store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware))
    )

    return store
}
export default configureStore