import { Change_Collape, Change_Toggle, } from './action'

const initialState = {
    changecollape: false,
    changeToggle: false,
}

export default function sort(state = initialState, action) {
    switch (action.type) {
        case Change_Collape:
            return { ...state, changecollape: !state.changecollape }
        case Change_Toggle:
            return { ...state, changeToggle: !state.changeToggle }
        default:
            return state
    }

}
