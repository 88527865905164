const tagLabelActive = {
    0: "default",
    1: "green"
}

const formatNumber = (num, digit) => {
    if (num) {
        return num.toFixed(digit).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return null;
}

const formatNumberZero = (num, digit) => {
    if (num) {
        return num.toFixed(digit).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    return 0;
}


const CovertJson = (value) => {
    if (value) {
        let data = JSON.parse(value);

        return data.toString();
    }
};

export {
    tagLabelActive,
    formatNumber,
    CovertJson,
    formatNumberZero
}