const Api = {
    ACT_TOKEN : "/api/actran/token",
    ACT_GET_SALE_EMPLOYEE : "/api/actran/sale/mployee/find-by-code",
    ACT_SALE_EMPLOYEE : "/api/actran/sale/employee",

    AUTH_LOGIN: "/user/login",
    AUTH_CHANGE_PASSWORD: "/user/change/password",
    REGION_LIST : "/api/address/region-list",
    PROVINCE_LIST : "/api/address/province-list",
    DISTRICT_LIST : "/api/address/district-list",
    SUB_DISTRICT_LIST : "/api/address/subdistrict-list",
    ADDRESS_BY_POSTCODE: "/api/address/by-postcode/",


    SALE_SEARCH_LIST:"/api/sale/search-list",
    SALE_CREATE:"/api/sale/create",
    SALE_UPDATE:"/api/sale/",
    SALE_DEACTIVATE:"/api/sale/de-activate/",
    SALE_SEARCH_UID:"/api/sale/uid/",
    SALE_ACTIVATE:"/api/sale/activate/",
    SALE_SEARCH_CODE:"/api/sale/search/",

    COMPANY_DROPDOWN: "/api/company/dropdown-list",
    COMPANY_SEARCH_LIST: "/api/company/search-list",
    COMPANY_CREATE: "/api/company/create",
    COMPANY_UPDATE: "/api/company/",


    DISTRIBUTOR_DROPDOWN: "/api/distributor/dropdown-list",
    DISTRIBUTOR_SEARCH_LIST: "/api/distributor/search-list",
    DISTRIBUTOR_CREATE: "/api/distributor/create",
    DISTRIBUTOR_UPDATE: "/api/distributor/",

    ROLE_DROPDOWN: "/api/role/dropdown-list",
    ROLE_SEARCH_LIST: "/api/role/search-list",
    ROLE_CREATE: "/api/role/create",
    ROLE_UPDATE: "/api/role/",

    SALETYPE_DROPDOWN: "/api/sale/type",
    SALETYPE_SEARCH_LIST: "/api/sale/type/search-list",
    SALETYPE_CREATE: "/api/sale/type/create",
    SALETYPE_UPDATE: "/api/sale/type/",
    
    USER_SEARCH_LIST: "/user/search-list",
    USER_CREATE: "/user/create",
    USER_UPDATE: "/user/",

    UTILITY_SALE_UNLINK_RICHMENU: "/api/utility/sale/unlink/rich-menu",
    UTILITY_SALE_ADD_USER_RICHMENU: "/api/utility/sale/add-user/rich-menu",
    UTILITY_FIND_LIFF: "/api/utility/find-liff/",

    OTP: "/api/otp/request/",


};
export default Api;