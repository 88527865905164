import React, { useEffect, useState } from 'react'
import HeaderApp from '../../header/HeaderApp'
import { Breadcrumb, Form, Input, Select, Button, Space, Table, Tag, Modal, Switch, Pagination } from 'antd'
import { AiOutlinePlus, AiOutlineEdit, AiOutlineDelete, AiOutlineSearch } from "react-icons/ai";
import MenuApp from '../../constants/MenuApp';
import { RiEditCircleFill, RiAddCircleFill } from "react-icons/ri";
import { Grid } from '@mui/material'
import responseHandleSrv from '../../services/responseHandleSrv';
import axios from '../../axios';
import { authHeader } from '../../services/auth-header';
import Api from '../../constants/Api';
import { tagLabelActive } from '../../services/function';

export default function User() {
    const { Option } = Select;
    const { confirm } = Modal;
    const [visibleCreate, setVisibleCreate] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);

    const [data, setData] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isOverlay, setOverlay] = useState(false);
    const [pageData, setPageData] = useState({
        currentPage: 1,
        pageSize: 10,
        totalPage: '',
        previousPage: '',
        nextPage: '',
        totalcount: '',
        goToPage: ''
    });
    const [filter, setFilter] = useState({
        search: '',
        RoleId: '',
        IsActive: ''
    });
    const [valueID, setValueID] = React.useState('');
    const [form] = Form.useForm();
    const [formadd] = Form.useForm();
    const [formedit] = Form.useForm();

    useEffect(() => {
        onClear();
        getRoleList();
    }, [])

    const getData = (page, pageSize, search, RoleId) => {
        (async () => {
            setIsLoading(true);
            await axios.get(`${Api.USER_SEARCH_LIST}`, {
                headers: authHeader(),
                params: {
                    search,
                    RoleId,
                    page,
                    skip: pageSize,
                }
            }).then(response => {
                if (response.data.success) {
                    let data = response.data.data.list;
                    setData(data);
                    let dd = {
                        ...pageData,
                        "currentPage": page,
                        "pageSize": pageSize,
                        "totalPage": response.data.totalPage,
                        "previousPage": response.data.previousPage,
                        "nextPage": response.data.nextPage,
                        "totalcount": response.data.data.totalCount,
                        "goToPage": ''
                    }
                    setPageData(dd);
                }
                setIsLoading(false)
            }).catch(err => {
                setIsLoading(false)
                responseHandleSrv.handleError(err);
            });
        })();
    }
    const getRoleList = () => {
        (async () => {
            await axios.get(`${Api.ROLE_DROPDOWN}`, {
                headers: authHeader()
            }).then(response => {
                if (response.data) {
                    setRoleList(response.data.data);
                }
            })
        })();
    }
    const onClear = () => {
        form.resetFields();
        getData(1, pageData.pageSize, '', '');
        let dd = {
            ...pageData,
            "currentPage": 1,
            "pageSize": pageData.pageSize,
            "totalPage": '',
            "previousPage": '',
            "nextPage": '',
            "totalcount": ''
        }
        setPageData(dd);

        let ft = {
            ...filter,
            "search": '',
            "RoleId": ''
        }
        setFilter(ft);
    }
    const onChangePage = (page, pageSize) => {

        let pp = {
            ...pageData,
            "currentPage": page,
            "pageSize": pageSize
        }
        setPageData(pp);

        getData(page, pageSize, filter.search);

    }
    const showModdalCreate = () => {
        setVisibleCreate(true);
    }


    const showModalEdit = (record) => {
        setVisibleEdit(true);
        setValueID(record.Id);

        let Role = record.RoleId ? roleList.find(role => role.Id == record.RoleId) ? record.RoleId : null : null;

        formedit.resetFields();
        formedit.setFieldsValue({
            'Username': record.Username ? record.Username : '',
            'Email': record.Email ? record.Email : '',
            'FirstName': record.FirstName ? record.FirstName : '',
            'LastName': record.LastName ? record.LastName : '',
            'MobileNo': record.MobileNo ? record.MobileNo : '',
            'LineId': record.LineId ? record.LineId : '',
            'RoleId': Role ? record.RoleId : null,
            'IsActive': record.IsActive === 1 ? true : false,
        });

    }
    const showModalDelete = (record) => {
        confirm({
            title: 'คุณแน่ใจที่จะลบรายการนี้?',
            okText: 'ใช่',
            cancelText: 'ไม่',
            okType: 'danger',
            okButtonProps: { className: 'dangerOutlAnimate' },
            cancelButtonProps: { className: 'radiusBtn' },
            centered: true,
            closable: true,
            onOk() {
                (async () => {
                    setIsLoading(true);
                    await axios.delete(`${Api.USER_UPDATE + record.Id}`, {
                        headers: authHeader()
                    }).then(response => {
                        if (response.data.success) {
                            responseHandleSrv.handleSuccess(response);
                            onClear();
                        }
                    }).catch(err => {
                        setIsLoading(false)
                        responseHandleSrv.handleError(err);
                    });
                })();
            },
            onCancel() { },
        });
    }

    const handleOk = () => {
        setVisibleCreate(false);
    }
    const handleCancel = () => {
        setVisibleCreate(false);
        formadd.resetFields();
        onClear();
    }
    const handleCancelEdit = () => {
        setVisibleEdit(false);
        setValueID('');
        formedit.resetFields();
        onClear();
    }

    const onFinish = (values) => {
        let search = values.search !== undefined ? values.search : '';
        let RoleId = values.RoleId !== undefined ? values.RoleId : '';
        let ft = { ...filter, "search": search, "RoleId": RoleId }
        setFilter(ft);
        getData(1, pageData.pageSize, search, RoleId);
    }

    const onFinishAdd = (values) => {
        let params = {
            Username: values.Username !== undefined ? values.Username : '',
            Email: values.Email !== undefined ? values.Email : '',
            FirstName: values.FirstName !== undefined ? values.FirstName : '',
            LastName: values.LastName !== undefined ? values.LastName : '',
            MobileNo: values.MobileNo !== undefined ? values.MobileNo : '',
            Password: values.Password !== undefined ? values.Password : '',
            LineId: values.LineId !== undefined ? values.LineId : '',
            RoleId: values.RoleId !== undefined ? values.RoleId : '',
            IsActive: values.IsActive !== undefined ? values.IsActive : false
        };

        (async () => {
            setIsLoading(true);
            setOverlay(true);
            await axios.post(`${Api.USER_CREATE}`, params, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    responseHandleSrv.handleSuccess(response);
                    handleCancel();
                    setIsLoading(false);
                    setOverlay(false);
                }
            }).catch(err => {
                setIsLoading(false)
                setOverlay(false);
                responseHandleSrv.handleError(err);
            });
        })();


    }

    const onFinishEdit = (values) => {

        let params = {
            Username: values.Username !== undefined ? values.Username : '',
            Email: values.Email !== undefined ? values.Email : '',
            FirstName: values.FirstName !== undefined ? values.FirstName : '',
            LastName: values.LastName !== undefined ? values.LastName : '',
            MobileNo: values.MobileNo !== undefined ? values.MobileNo : '',
            LineId: values.LineId !== undefined ? values.LineId : '',
            RoleId: values.RoleId !== undefined ? values.RoleId : '',
            IsActive: values.IsActive !== undefined ? values.IsActive : false
        };

        (async () => {
            setIsLoading(true);
            setOverlay(true);
            await axios.put(`${Api.USER_UPDATE + valueID}`, params, {
                headers: authHeader()
            }).then(response => {
                if (response.data.success) {
                    responseHandleSrv.handleSuccess(response);
                    handleCancelEdit();
                    setIsLoading(false);
                    setOverlay(false);
                }
            }).catch(err => {
                setIsLoading(false)
                setOverlay(false);
                responseHandleSrv.handleError(err);
            });
        })();
    }
    const columns = [
        {
            title: 'Username',
            dataIndex: 'Username',
            key: 'Username',
            align: 'center'
        },
        {
            title: 'อีเมล',
            dataIndex: 'Email',
            key: 'Email',
            align: 'center',
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'ชื่อ',
            dataIndex: 'FirstName',
            key: 'FirstName',
            align: 'center',
            render: (text, record) => (
                <>
                    {record.FirstName ? <div>{record.FirstName} {record.LastName}</div> : <div></div>}
                </>

            ),
        },
        {
            title: 'เบอร์โทรศัพท์',
            dataIndex: 'MobileNo',
            key: 'MobileNo',
            align: 'center',
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'ไอดีไลน์',
            dataIndex: 'LineId',
            key: 'LineId',
            align: 'center',
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'Role',
            dataIndex: 'RoleName',
            key: 'RoleName',
            align: 'center',
            render: (text, record) => (
                <div>{text ? text : '-'}</div>
            )
        },
        {
            title: 'สถานะการใช้งาน',
            dataIndex: 'IsActive',
            key: 'IsActive',
            align: 'center',
            render: (text, record) => (
                <>
                    {
                        text === 0 ? <div><Tag color={tagLabelActive[record.IsActive]}>ปิดการใช้งาน</Tag></div>
                            : <div><Tag color={tagLabelActive[record.IsActive]}>เปิดการใช้งาน</Tag></div>
                    }

                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: 110,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="text" shape="circle" icon={<AiOutlineEdit />} onClick={() => showModalEdit(record)} />
                    <Button type="text" shape="circle" danger icon={<AiOutlineDelete />} onClick={() => showModalDelete(record)} />
                </Space>
            ),
        },
    ];
    return (
        <>
        <div id="overlay" style={{display: isOverlay === true ? "block" : "none"}}><div class="text-overlay-center">Loading...</div></div>
            <div className="page-container">
                <HeaderApp />
                <div className='pageSider'>
                    <MenuApp />
                    <div className="main-content">

                        {/* modal create */}
                        <Modal
                            title={<div className='modalHeader'><RiAddCircleFill size='20px' />&nbsp;&nbsp;Add User</div>}
                            open={visibleCreate}
                            footer={false}
                            onOk={handleOk}
                            onCancel={handleCancel}
                            centered='true'
                            className='modalCreate'
                        >
                            <Form
                                layout='vertical'
                                form={formadd}
                                name='create-user'
                                onFinish={onFinishAdd}
                                onFinishFailed={responseHandleSrv.onFinishFailed}
                                initialValues={{ 'IsActive': true }}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Username'
                                            name='Username'
                                            rules={[{ required: true, message: 'กรอก Username' }]}
                                        >
                                            <Input placeholder='กรอก Username' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='อีเมล'
                                            name='Email'
                                            rules={[{ required: true, message: 'กรอกอีเมล' }]}
                                        >
                                            <Input placeholder='กรอกอีเมล' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='รหัสผ่าน'
                                            name='Password'
                                            rules={[{ required: true, message: 'กรอกรหัสผ่าน' }]}
                                        >
                                            <Input.Password placeholder='กรอกรหัสผ่าน' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ชื่อ'
                                            name='FirstName'
                                            rules={[{ required: true, message: 'กรอกชื่อ' }]}
                                        >
                                            <Input placeholder='กรอกชื่อ' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='นามสกุล'
                                            name='LastName'
                                            rules={[{ required: true, message: 'กรอกนามสกุล' }]}
                                        >
                                            <Input placeholder='กรอกนามสกุล' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='เบอร์โทรศัพท์'
                                            name='MobileNo'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรอกเบอร์โทรศัพท์'
                                                },
                                                {
                                                    pattern: /^[0][0-9]{9}$\b/,
                                                    message: 'เบอร์โทรศัพท์มือถือควรเป็นตัวเลข ขึ้นต้นด้วย 0 และมี 10 หลัก'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='กรอกเบอร์โทรศัพท์' maxLength="10" minLength="10" />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ไอดีไลน์'
                                            name='LineId'
                                        >
                                            <Input placeholder='กรอกไอดีไลน์' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Role'
                                            name='RoleId'
                                            rules={[{ required: true, message: 'เลือก Role' }]}
                                        >
                                            <Select
                                                placeholder='เลือก Role'
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                            >
                                                {roleList && roleList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='สถานะการใช้งาน'
                                            name='IsActive'
                                            valuePropName='checked'>
                                            <Switch defaultChecked checkedChildren='เปิด' unCheckedChildren='ปิด' />
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                                <div className='df-flexEnd'>
                                    <Button onClick={() => handleCancel()} className='radiusBtn'>Cancel</Button>&nbsp;
                                    <Button type='primary' htmlType='submit' className='primaryBtnAnimate'>Save</Button>
                                </div>
                            </Form>
                        </Modal>

                        {/* modal edit */}
                        <Modal
                            title={<div className='modalHeader'><RiEditCircleFill size='20px' />&nbsp;&nbsp;Edit User</div>}
                            open={visibleEdit}
                            footer={false}
                            onOk={handleOk}
                            onCancel={handleCancelEdit}
                            centered='true'
                            className='modalCreate'
                        >
                            <Form
                                layout='vertical'
                                form={formedit}
                                name='edit-user'
                                onFinish={onFinishEdit}
                                onFinishFailed={responseHandleSrv.onFinishFailed}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Username'
                                            name='Username'
                                            rules={[{ required: true, message: 'กรอก Username' }]}
                                        >
                                            <Input placeholder='กรอก Username' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='อีเมล'
                                            name='Email'
                                            rules={[{ required: true, message: 'กรอกอีเมล' }]}
                                        >
                                            <Input placeholder='กรอกอีเมล' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ชื่อ'
                                            name='FirstName'
                                            rules={[{ required: true, message: 'กรอกชื่อ' }]}
                                        >
                                            <Input placeholder='กรอกชื่อ' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='นามสกุล'
                                            name='LastName'
                                            rules={[{ required: true, message: 'กรกรอกนามสกุลอกชื่อ' }]}
                                        >
                                            <Input placeholder='กรอกนามสกุล' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='เบอร์โทรศัพท์'
                                            name='MobileNo'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรอกเบอร์โทรศัพท์'
                                                },
                                                {
                                                    pattern: /^[0][0-9]{9}$\b/,
                                                    message: 'เบอร์โทรศัพท์มือถือควรเป็นตัวเลข ขึ้นต้นด้วย 0 และมี 10 หลัก'
                                                }
                                            ]}
                                        >
                                            <Input placeholder='กรอกเบอร์โทรศัพท์' maxLength="10" minLength="10" />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='ไอดีไลน์'
                                            name='LineId'
                                        >
                                            <Input placeholder='กรอกไอดีไลน์' />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='Role'
                                            name='RoleId'
                                            rules={[{ required: true, message: 'เลือก Role' }]}
                                        >
                                            <Select
                                                placeholder='เลือก Role'
                                                showSearch
                                                allowClear={true}
                                                optionFilterProp="children"
                                            >
                                                {roleList && roleList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Form.Item
                                            label='สถานะการใช้งาน'
                                            name='IsActive'
                                            valuePropName='checked'>
                                            <Switch defaultChecked checkedChildren='เปิด' unCheckedChildren='ปิด' />
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                                <div className='df-flexEnd'>
                                    <Button onClick={() => handleCancelEdit()} className='radiusBtn'>Cancel</Button>&nbsp;
                                    <Button type='primary' htmlType='submit' className='primaryBtnAnimate'>Save</Button>
                                </div>
                            </Form>
                        </Modal>

                        <div className='pdPage'>
                            <div className='buttonHeader' >
                                <div>
                                    <b className='pageTitle'>User</b>
                                    <Breadcrumb className='breadcrumbStyle' items={[{ title: 'User', href: '/backend/user' }]} />
                                </div>
                                <Button type='primary' onClick={() => showModdalCreate()} className='df-centerY primaryBtnAnimate'><AiOutlinePlus />&nbsp;&nbsp;Add User</Button>
                            </div>

                            <div className='searchCard'>
                                <Form
                                    form={form}
                                    layout='vertical'
                                    name='search-table'
                                    onFinish={onFinish}
                                >
                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                            <Form.Item label='ค้นหา' name='search'>
                                                <Input placeholder='ชื่อ นามสกุล เบอร์โทรศัพท์'></Input>
                                            </Form.Item>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} lg={4}>
                                            <Form.Item label='Role' name='RoleId'>
                                                <Select
                                                    placeholder='Role'
                                                    showSearch
                                                    allowClear={true}
                                                    optionFilterProp="children"
                                                >
                                                    {roleList && roleList.map((s) => <Option value={s.Id} key={s.Id}>{s.NameTh}</Option>)}
                                                </Select>
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} className='df-centerY'>Result: {pageData.totalcount} {pageData.totalcount > 0 ? (pageData.totalcount === 1 ? "item" : "items") : ""}</Grid>
                                        <Grid item xs={12} sm={6} className='searchBtnOrg'>
                                            <Button type='primary' htmlType='submit' className='df-centerY primaryBtnAnimate'><AiOutlineSearch />&nbsp;&nbsp;ค้นหา</Button>&nbsp;
                                            <Button danger className='df-centerY dangerOutlAnimate' onClick={() => onClear()}><AiOutlineDelete />&nbsp;&nbsp;เคลียร์</Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </div>
                            <div>
                                {isLoading === true ?
                                    <div className="linearloader" /> :
                                    <>
                                        <Table
                                            className='headerTable-color listData-card'
                                            columns={columns}
                                            dataSource={data}
                                            pagination={false}
                                            scroll={{ x: 1490 }}
                                            rowKey={record => record.Id}
                                            rowClassName={'tableBody'}
                                        />
                                        <Pagination
                                            total={pageData.totalcount}
                                            showSizeChanger
                                            showQuickJumper
                                            current={pageData.currentPage}
                                            defaultPageSize={pageData.pageSize}
                                            onChange={onChangePage}
                                            responsive={true}
                                            className="tableBottom"
                                        />
                                    </>
                                }
                            </div>
                        </div>

                    </div >
                </div >
            </div >
        </>
    )
}



