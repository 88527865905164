import axios from "../axios";
import Api from "../constants/Api";
import { authBasicHeader } from "../services/auth-header";

const sendOtp = (mobileNo) => {
    
    return  axios.post(`${Api.OTP+mobileNo}`,{},{ headers: authBasicHeader() });
};

const verifyOtp = (mobileNo, pinCode) => {
    let path = `${Api.OTP+mobileNo+'/'+pinCode}`;
    // console.log('path',path);
    return  axios.post(`${Api.OTP+mobileNo+'/'+pinCode}`,{}, { headers: authBasicHeader() });
};

export default {
    sendOtp,
    verifyOtp
}