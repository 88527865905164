import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { FaUserTie, FaBuilding, FaHubspot, FaUsers, FaUser, FaUserCog } from "react-icons/fa";
import * as actions from '../services/action'

function MenuApp() {
    const collapsedd = useSelector(state => state.mains.changecollape)
    const toggledd = useSelector(state => state.mains.changeToggle)
    const dispatch = useDispatch();
    const onchangecollape = () => {
        dispatch(actions.changecollape());
        dispatch(actions.changeToggle());
    }
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    const useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

        useEffect(() => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);

        }, []);

        return windowDimensions;
    }

    const { height, width } = useWindowDimensions();
    const path = window.location.pathname;
    return (
        <div className='siderBarStyle'>
            <Sidebar
                collapsed={width <= 768 ? false : collapsedd}
                toggled={toggledd}
                breakPoint="md"
                onBackdropClick={onchangecollape}
                transitionDuration={500}
                rootStyles={{
                    height: '100vh',
                    backgroundColor: 'rgb(249, 249, 249)'
                }}
            >
                <Menu
                    className='menuApp'
                    menuItemStyles={{
                        button: ({ level, active }) => {
                            if (level === 0 || level === 1) {
                                return {
                                    margin: '10px',
                                    borderRadius: '8px',
                                    paddingLeft: '12px',
                                    paddingRight: '12px',
                                    boxShadow: collapsedd ? '' : (active ? '0 2px 8px rgba(0,0,0,.15)' : ''),
                                    color: active ? '#434343' : '#595959',
                                    transition: 'all .25s ease',
                                    "&:hover": {
                                        backgroundColor: '#f0f0f0',
                                        color: '#1890ff',
                                        transform: 'translateY(-0.1em)',
                                        transition: 'all .25s ease',
                                    },
                                }
                            }
                        },
                        icon: ({ level, active }) => {
                            if (level === 0 || level === 1) {
                                return {
                                    fontSize: '18px',
                                    borderRadius: '6px',
                                    backgroundColor: active ? '#1890ff' : '#ffffff',
                                    color: active ? 'white' : '#8c8c8c',
                                    boxShadow: '0 4px 6px rgba(0,0,0,.12)'
                                }
                            }
                        },
                        subMenuContent: ({ level }) => ({ backgroundColor: 'rgb(249, 249, 249)' }),
                    }}
                >
                    <MenuItem icon={<FaUserTie />} active={path == '/backend/sale'} component={<Link to="/backend/sale" />}>Sale</MenuItem>
                    <MenuItem icon={<FaBuilding />} active={path == '/backend/company'} component={<Link to="/backend/company" />}>Company</MenuItem>
                    <MenuItem icon={<FaHubspot />} active={path == '/backend/distributor'} component={<Link to="/backend/distributor" />}>Distributor</MenuItem>
                    <MenuItem icon={<FaUsers />} active={path == '/backend/role'} component={<Link to="/backend/role" />}>Role</MenuItem>
                    <MenuItem icon={<FaUser />} active={path == '/backend/user'} component={<Link to="/backend/user" />}>User</MenuItem>
                    <MenuItem icon={<FaUserCog />} active={path == '/backend/saleType'} component={<Link to="/backend/saleType" />}>Sale Type</MenuItem>
                </Menu>
            </Sidebar >
        </div >
    )
}

export default MenuApp;